import React, { useState, useEffect, useContext } from "react";
import useSWR from "swr";

import { getTheTokenFromStorage } from "../../../services/auth";
import { fetcherAuth } from "../../../services/fetcher";
import LoaderModal from "../../LoaderModal";
import DeleteModal from "../../DeleteModal";

import { deleteLink } from "../../../services/affiliate";
import { toast } from "react-toastify";

import { IconContext } from "react-icons";
import { AiFillDelete, AiFillCopy } from "react-icons/ai";

const MyLinks = () => {
  const token = getTheTokenFromStorage();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [currentLink, setCurrentLink] = useState(null);

  const { data, error, mutate } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/affiliate/referrers/links`,
      token,
    ],
    fetcherAuth
  );

  const handleDelete = async () => {
    setOpenModal(false);
    setLoading(true);
    const res = await deleteLink(currentLink, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="w-full h-full flex flex-col  px-4 py-6 overflow-y-auto space-y-2">
      <h1 className="text-2xl font-semibold text-reghda-blue mb-4">
        روابط الخاصة بي
      </h1>
      {data && data.length === 0 && (
        <div className="text-center p-4 border rounded-md text-reghda-blue">
          ليس لديك روابط
        </div>
      )}
      {data &&
        data.length > 0 &&
        data?.map((item) => (
          <div
            key={item.id}
            className="flex items-center p-2 shadow-md rounded-md bg-white">
            <div
              onClick={() => {
                const url = `https://ragdasairafi369.com/payed-courses/course-detail/${item.ref_slug}?ref_by=${item.coupon}`;
                window.open(url, "_blank");
              }}
              className="cursor-pointer w-full flex items-center p-2 mx-2 text-reghda-blue">
              <div className="w-1/4">رابط ذو القسيمة :</div>
              <div className="w-1/4">{item?.coupon}</div>
            </div>
            <button
              className="cursor-pointer bg-green-300 text-white px-4 py-2 rounded-md ml-2 outline-none focus:outline-none"
              onClick={async () => {
                await navigator.clipboard.writeText(item.ref_link);
                toast("تم نسخ");
              }}>
              <IconContext.Provider
                value={{ size: "1em", style: { color: "#fff" } }}>
                <AiFillCopy></AiFillCopy>
              </IconContext.Provider>
            </button>
            <button
              className="cursor-pointer bg-red-400 text-white px-4 py-2 rounded-md ml-2 outline-none focus:outline-none"
              onClick={() => {
                setCurrentLink(item.id);
                setOpenModal(true);
              }}>
              <IconContext.Provider
                value={{ size: "1em", style: { color: "#fff" } }}>
                <AiFillDelete></AiFillDelete>
              </IconContext.Provider>
            </button>
          </div>
        ))}
      <DeleteModal
        isOpen={openModal}
        closing={() => setOpenModal(false)}
        deleteFn={handleDelete}
      />
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default MyLinks;
