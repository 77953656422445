import React from "react";
import { Link } from "react-router-dom";

const AboutThree = () => {
  return (
    <div className="text-center h-80 flex flex-col x-sm:items-center md:flex-row md:px-20 py-16 x-sm:mb-16">
      <div className="flex-1">
        <img
          className="x-sm:w-36 x-sm:h-36 md:w-64 md:h-64 lg:w-about-pics lg:h-about-pics 2xl:w-about-pics-xl 2xl:h-about-pics-xl"
          src="images/about-3.png"
          alt="Layer"
        />
      </div>
      <div className="flex-1 text-right flex flex-col text-gray-600">
        <div className="x-sm:mb-4 x-sm:mt-4 md:mb-10">
          <h1 className="sm:text-lg lg:text-4xl 2xl:text-8xl text-center md:mr-12">
            لماذا تختار رغده صيرفي{" "}
            <span className="bg-indigo-200">مدربة لك</span> ؟
          </h1>
        </div>
        <div className="x-sm:mr-4 tracking-wide x-sm:text-xs md:text-base lg:text-2xl 2xl:text-3xl">
          <p className="my-4">لديها خبرة في التدريب أكثر من عشر سنوات ـ</p>
          <p className="my-4">حضرت العديد من الدورات وورش العمل ـ</p>
          <p className="my-4">تمتلك العطاء العالي ـ</p>
          <p className="my-4">
            ذات أسلوب سلس و بسيط و مفيد مناسب لجميع الشخصيات ـ
          </p>
        </div>
        <Link
          to="/why-to-choose-raghda"
          className="text-center bg-gradient-to-r from-pink-400 to-indigo-300 text-white mt-6 py-2 px-4 rounded x-sm:text-xs x-sm:w-24 2xl:w-48 2xl:text-3xl"
        >
          لقراءة المزيد
        </Link>
      </div>
    </div>
  );
};

export default AboutThree;
