import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { sendEmailResetPassword } from "../../../services/resetPassword";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [changeSuccess, setChangeSuccess] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-indigo-200 h-screen w-1/2 overview-sl"></div>
      <div className="flex justify-center items-start w-full md:w-1/2  md:items-center">
        <div
          className="flex flex-col justify-center items-center p-4 rounded-3xl"
          style={{
            width: "40vw",
            minWidth: 280,
          }}>
          <img
            src="/images/bottom_logo.png"
            style={{ maxWidth: 100 }}
            className="pb-8"
          />
          {changeSuccess ? (
            <>
              <p className="text-xl text-center mb-8">
                لقد أرسلنا لك بريدًا إلكترونيًا لتغيير كلمة المرور الخاصة بك
              </p>
              <Link
                to="/"
                className="bg-reghda-black text-pink-300 text-center w-3/4 x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg my-8">
                اذهب إلى الصفحة الرئيسية
              </Link>
            </>
          ) : (
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                const { email } = values;
                const res = await sendEmailResetPassword(email);
                console.log(res);

                if (res && res.data && res.status === 200) {
                  setChangeSuccess(true);
                } else {
                  alert("حدث خطأ ، حاول مرة أخرى ");
                }
                setSubmitting(false);
              }}>
              <Form className="w-full">
                <Field
                  className="bg-pink-200 w-full text-white mb-4 placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300 "
                  placeholder="البريد الالكتروني"
                  name="email"
                  type="email"
                />
                <div className="text-red-400 border-red-400 mb-4 md:text-sm xl:text-base text-center">
                  <ErrorMessage name="email" />
                </div>
                <button
                  className="bg-reghda-black text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg  focus:outline-none	active:outline-none"
                  type="submit">
                  استرجاع كلمة السر
                </button>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
