import React from "react";
import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <div className="h-80">
      <div className="about-two-bg-shape"></div>
      <div className="about-two-content text-center h-80 flex flex-col-reverse x-sm:items-center md:flex-row lg:space-x-36 py-16 md:py-2 md:pl-36">
        <div className="flex-1 text-right flex flex-col text-gray-600">
          <div className="mb-10">
            <h1 className="x-sm:text-lg sm:text-2xl lg:text-6xl 2xl:text-8xl text-right font-semibold">
              قالوا عن رغده صيرفي وبرامجها
            </h1>
          </div>
          <div className="tracking-wide leading-loose x-sm:text-xs md:text-base lg:text-2xl 2xl:text-3xl">
            <p>هنا تجد بعض ما قيل عن الاستاذة رغده</p>
            {/* <p>من طرف الزملاء و الأصدقاء و العملاء</p> */}
          </div>
          <Link
            to="/people-said"
            className="bg-gradient-to-r from-pink-400 to-indigo-300 text-white mt-6 py-2 px-4 rounded x-sm:text-xs x-sm:w-24 2xl:w-48 2xl:text-3xl"
          >
            للإطلاع على الرسائل
          </Link>
        </div>
        <div className="flex-1">
          <img
            className="x-sm:w-36 x-sm:h-36 md:w-64 md:h-64 lg:w-about-pics lg:h-about-pics 2xl:w-about-pics-xl 2xl:h-about-pics-xl 2xl:ml-32"
            src="images/about-2.png"
            alt="Layer"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
