import React from "react";
import ResetPassword from "./content/ResetPassword";
import SendEmailResetPassword from "./content/SendEmailResetPassword";

const Page = ({ match }) => {
  return (
    <div>
      {match.params.userId && match.params.token ? (
        <ResetPassword params={match.params} />
      ) : (
        <SendEmailResetPassword />
      )}
    </div>
  );
};

export default Page;
