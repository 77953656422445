import Axios from "axios";
import { domain } from "../";

export async function addComment(data) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/reviews/courses/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteComment(commentId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/reviews/courses/${commentId}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editComment(data) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/reviews/courses/${data.commentId}/`,
      { comment: data.detail, rating: data.rating }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addResponse({ user, parent, body }) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/comments/answers/`,
      {
        user,
        parent,
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editResponse({ responseId, body }) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/comments/answers/${responseId}/`,
      {
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function deleteResponse(responseId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/comments/answers/${responseId}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addResponseReview({ user, parent, body }) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/courses/`,
      {
        user,
        parent,
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editResponseReview({ responseId, body }) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/courses/${responseId}/`,
      {
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function deleteResponseReview(responseId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/courses/${responseId}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
