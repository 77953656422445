import React, { useState } from "react";
import useSWR from "swr";
import Modal from "./Modal";
import { fetcher } from "../../../services/fetcher";

const Certifications = () => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const { data, error } = useSWR(
    "https://ragdasairafi369.smartiniaclient.com/certificates/",
    fetcher
  );

  return (
    <div className="min-h-80 h-full space-y-8 rtl">
      <div className="w-11/12 xl:w-9/12 mx-auto flex flex-wrap gap-2 mt-8">
        {data &&
          data.map((item) => (
            <div className="flex-49 lg:flex-32 mb-4 md:mb-8 bg-white p-2 rounded-md shadow-md">
              <h1 className="text-center text-base md:text-xl font-semibold text-reghda-black">
                {item.title}
              </h1>
              <img
                src={item.attestation}
                alt={item.slug}
                onClick={() => {
                  setOpen(true);
                  setImage(item.attestation);
                }}
                className="cursor-pointer border"
              />
              <p className="text-center text-sm md:text-base font-medium text-reghda-black mt-2 border rounded-md">
                {item.description}
              </p>
            </div>
          ))}
      </div>
      <Modal
        open={open}
        handleClose={() => {
          setOpen(false);
          setImage("");
        }}
        image={image}
      />
    </div>
  );
};

export default Certifications;
