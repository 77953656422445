import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { createLink } from "../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../services/auth";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

const CreateLinkModal = ({ isOpen, closing, id }) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [link, setLink] = useState(null);

  const token = getTheTokenFromStorage();

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 10001,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          // width: screenWidth >= 768 ? "40%" : "80%",
          width: "30%",
        },
      }}
    >
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={() => {
            closing();
            setLink(null);
            setText("");
          }}
          class="self-end cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      {!link && !loading && (
        <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
          <h3 className="text-reghda-blue font-semibold">معلومات الرابط</h3>
          <input
            onChange={(event) => setText(event.target.value)}
            value={text}
            placeholder="قسيمة"
            type="text"
            className="x-sm:w-full md:w-3/4 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none"
          />
          <button
            onClick={async () => {
              setLoading(true);
              let res = await createLink(
                {
                  coupon: text && text.length > 0 ? text : null,
                  campaign: id,
                },
                token
              );
              console.log(res);
              if (res.status === 201) {
                setLoading(false);
                setLink(res.data.ref_link);
              } else {
                setLoading(false);
                toast(`${res.data.message}`);
                closing();
              }
            }}
            className="x-sm:w-full md:w-3/4 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none"
          >
            أنشئ
          </button>
        </div>
      )}
      {link && !loading && (
        <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
          <h3 className="text-reghda-blue font-semibold rtl">الرابط :</h3>
          <h3 className="text-sm text-reghda-blue font-semibold">{link}</h3>
        </div>
      )}
      {loading && (
        <div className="flex flex-col items-center justify-center">
          <Loader type="Oval" height={80} width={80} color="#91b6c6" />
        </div>
      )}
    </Modal>
  );
};

export default CreateLinkModal;
