import React, { useState, useEffect, useContext } from "react";
import useSWR from "swr";
import {
  addWithdrawOption,
  deleteWithdrawOption,
} from "../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../services/auth";
import { fetcherAuth } from "../../../services/fetcher";
import { AiTwotoneBank, AiFillDelete } from "react-icons/ai";
import LoaderModal from "../../LoaderModal";
import ModalDelete from "./ModalDelete";

const WithdrawOptions = () => {
  const token = getTheTokenFromStorage();

  const [loading, setLoading] = useState(false);
  const [withdrawOptionId, setWithdrawOptionId] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [account, setAccount] = useState({
    account_name: "",
    account_type: "",
    account_number: "",
  });

  const { data, error, mutate } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/withdraw/users/options/get`,
      token,
    ],
    fetcherAuth
  );

  const handleAddWithdrawOption = async () => {
    setLoading(true);
    const res = await addWithdrawOption(account, token);
    if (res?.status === 200) {
      mutate();
      setAccount({
        account_name: "",
        account_type: "",
        account_number: "",
      });
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const res = await deleteWithdrawOption(id, token);
    if (res?.status === 204) {
      mutate();
    } else {
      alert(res?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="w-full h-full flex flex-col  px-4 py-6 overflow-y-auto space-y-2">
      <h1 className="text-2xl font-semibold text-reghda-blue mb-4">
        إضافة خيار سحب
      </h1>
      <div className="flex justify-start items-center " style={{ gap: "3rem" }}>
        <label className="w-32"> إسم الحساب </label>
        <input
          placeholder="إسم الحساب "
          className="w-full border rounded-md p-2"
          type="text"
          value={account.account_name}
          onChange={(e) =>
            setAccount({ ...account, account_name: e.target.value })
          }
        />
      </div>
      <div className="flex justify-start items-center" style={{ gap: "3rem" }}>
        <label className="w-32">نوع الحساب</label>
        <input
          placeholder="نوع الحساب"
          className="w-full border rounded-md p-2"
          type="text"
          value={account.account_type}
          onChange={(e) =>
            setAccount({ ...account, account_type: e.target.value })
          }
        />
      </div>
      <div className="flex justify-start items-center" style={{ gap: "3rem" }}>
        <label className="w-32">رقم حساب</label>
        <input
          placeholder="رقم حساب"
          className="w-full border rounded-md p-2"
          type="text"
          value={account.account_number}
          onChange={(e) =>
            setAccount({ ...account, account_number: e.target.value })
          }
        />
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleAddWithdrawOption}
          className="px-4 py-2 rounded-md bg-reghda-blue text-white">
          اضف
        </button>
      </div>
      <h1 className="text-2xl font-semibold text-reghda-blue mb-4">
        خيارات السحب الخاصة بي{" "}
      </h1>
      {data?.length === 0 && (
        <div className="text-center p-4 border rounded-md text-reghda-blue">
          ليس لديك خيارات سحب
        </div>
      )}
      {data?.map((item) => (
        <div className="flex justify-between items-center border rounded-md p-2">
          <div className="flex items-center gap-2">
            <AiTwotoneBank className="text-reghda-blue border w-24 h-24 rounded-md" />
            <div>
              <div className="flex space-x-10">
                <p className="w-32"> إسم الحساب:</p>
                <p>{item.account_name}</p>
              </div>
              <div className="flex space-x-10 text-gray-500 text-sm">
                <p className="w-32">نوع الحساب:</p>
                <p>{item.account_type}</p>
              </div>
              <div className="flex space-x-10 text-gray-500 text-sm">
                <p className="w-32"> رقم حساب:</p>
                <p>{item.account_number}</p>
              </div>
            </div>
          </div>
          <AiFillDelete
            className="text-red-600  w-8 h-8 rounded-md cursor-pointer"
            onClick={() => {
              setWithdrawOptionId(item.id);
              setOpenModal(true);
            }}
          />
        </div>
      ))}
      <ModalDelete
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleDelete={() => handleDelete(withdrawOptionId)}
      />
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default WithdrawOptions;
