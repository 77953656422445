import React, { useEffect } from "react";
import CourseCard from "./CourseCard";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";

const CoursesList = ({ title }) => {
  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/free/`,
    fetcher
  );

  return (
    <div className="min-h-80">
      <div className="h-full">
        <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
          <h1 className="text-gray-700">{title}</h1>
        </div>
        <div className="card-container p-8 lg:p-16 text-gray-700">
          {/* free-course-page */}
          {data &&
            data.map((course, i) => (
              <CourseCard
                slug={course.slug}
                id={course.id}
                url={course.picture}
                name={course.title}></CourseCard>
            ))}
          {/* <CourseCard url="/images/free/1.png" name="كورسات مجانية"></CourseCard>
        <CourseCard url="/images/free/2.png" name="قنات اليوتوب"></CourseCard>
        <CourseCard url="/images/free/3.png" name="جلسات مجانية"></CourseCard>
        <CourseCard
          url="/images/free/4.png"
          name="روايات تدريبية مجانية"
        ></CourseCard> */}
        </div>
      </div>
    </div>
  );
};

export default CoursesList;
