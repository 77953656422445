import React, { useState, useContext, useEffect } from "react";
import { storeContext } from "./../../global/store";
import { useParams, useLocation, useHistory, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { getTheUserFromStorage } from "./../../services/auth";
import axios from "axios";
import { addToCart } from "../../services/cart";
import LoaderModal from "../LoaderModal";
import { MdArrowDropDownCircle } from "react-icons/md";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import parse from "html-react-parser";
import VideoPlayer from "../course-detail/VideoPlayer";
import Reviews from "../course-detail/Reviews";

import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";

const Details = () => {
  let { slug } = useParams();
  let history = useHistory();

  const { store, setStore } = useContext(storeContext);
  const [loading, setLoading] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState(null);
  const [showModules, setShowModules] = useState(null);

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/follow-up/details/${slug}`,
    fetcher
  );

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        `https://ragdasairafi369.smartiniaclient.com/students/mycourses/${data.id}`,
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setAlreadyPaid(res.data);
      /*  setAlreadyPaid(["aa", "aa"]); */
      return res;
    }
    data && store.isLogged && fetch();
  }, [data]);

  return (
    <div className="md:space-y-16 width-80-100 md:w-9/12 mx-auto mt-12">
      {/* {data && (
        <h1 className="text-reghda-blue font-extrabold text-2xl lg:text-4xl 2xl:text-5xl text-center ">
          {data.title}
        </h1>
      )} */}
      <div className=" flex flex-col md:flex-row ">
        <div className=" x-sm:p-8 2xl:p-20 x-sm:w-full flex-50">
          {data && (
            <div className="w-full">
              <div className="w-full flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
                <img
                  src={data.picture}
                  alt="course-pic"
                  className="w-full object-cover shadow-2xl mb-4"
                />
                <h6 className="text-center x-sm:text-sm sm:text-lg lg:text-xl 2xl:text-2xl">
                  {data.title}
                </h6>
              </div>
            </div>
          )}

          {((!store.isLogged && data) ||
            (store.isLogged &&
              data &&
              alreadyPaid &&
              alreadyPaid.length === 0)) && (
            <div className="mt-5 flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
              <p className="font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl border-1 p-2 rounded-xl">{`السعر : ${data.price} ريال`}</p>
            </div>
          )}

          {!store.isLogged && data && data.show_low_income_price && (
            <div className="mt-5 flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
              <p className="text-reghda-blue font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl border-1 p-2 rounded-xl">{`${data.low_income_name} : ${data.low_income_price} ريال`}</p>
            </div>
          )}

          <div className="flex justify-center mt-4 space-x-6 2xl:space-x-12">
            {store.isLogged &&
              data &&
              alreadyPaid &&
              alreadyPaid.length === 0 && (
                <button
                  onClick={async () => {
                    // add to cart
                    setLoading(true);
                    let res = await addToCart({
                      course: data.id,
                      user: getTheUserFromStorage().user_id,
                      is_low_income: false,
                    });
                    if (res.status === 201) {
                      setLoading(false);
                      ReactPixel.track("AddToCart", {
                        content_ids: [data.id],
                        content_name: data.title,
                        content_type: "course",
                        currency: "SAR",
                        value: data.price,
                      });
                      history.push("/cart");
                    } else if (res.status === 200) {
                      setLoading(false);
                      toast(`${res.data.message}`);
                    }
                  }}
                  className="bg-reghda-blue text-gray-700 px-8 py-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none">
                  أضف إلى السلة
                </button>
              )}

            {store.isLogged &&
              data &&
              alreadyPaid &&
              alreadyPaid.length > 0 && (
                <Link
                  to="/my-courses"
                  className="bg-reghda-pink text-gray-700 px-8 py-4 2xl:p-3 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl xl:text-xl">
                  إذهب إلى دوراتك
                </Link>
              )}

            {!store.isLogged && (
              <Link
                to="/login"
                className="bg-reghda-pink text-gray-700 px-8 py-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-xl">
                تسجيل للشراء
              </Link>
            )}
          </div>
          <div className="mt-4 text-center">
            {store.isLogged &&
              data &&
              alreadyPaid &&
              alreadyPaid.length === 0 &&
              data.show_low_income_price && (
                <button
                  onClick={async () => {
                    // add to cart low price
                    setLoading(true);
                    let res = await addToCart({
                      course: data.id,
                      user: getTheUserFromStorage().user_id,
                      is_low_income: true,
                    });
                    if (res.status === 201) {
                      setLoading(false);
                      ReactPixel.track("AddToCart", {
                        content_ids: [data.id],
                        content_name: data.title,
                        content_type: "course",
                        currency: "SAR",
                        value: data.price,
                      });
                      history.push("/cart");
                    } else if (res.status === 200) {
                      setLoading(false);
                      toast(`${res.data.message}`);
                    }
                  }}
                  className="bg-reghda-pink text-gray-700 px-8 py-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none">
                  <span className="ml-2 mr-2">
                    {data.low_income_name}
                    {/* إشتري بثمن ذوي الدخل المحدود */}
                  </span>
                  <span>
                    <span className="mr-2">:{data.low_income_price}</span>
                    <span className="mr-1">ريال</span>
                  </span>
                </button>
              )}
          </div>
        </div>
        <div
          className={`flex-50 flex flex-col space-y-8 2xl:space-y-16 items-center  mb-8 md:mb-0 justify-evenly h-1/2 `}>
          {/* {store.isLogged && data && alreadyPaid && alreadyPaid.length > 0 && (
            <h4 className=" text-center bg-reghda-blue text-gray-800 p-1 2xl:p-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl">
              {data.title}
            </h4>
          )} */}
          {((data && !store.isLogged) ||
            (data && alreadyPaid && alreadyPaid.length === 0)) && (
            <h4 className=" text-center bg-reghda-blue text-gray-800 p-1 2xl:p-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl">
              {/* حول البرنامج */}
              {data && data.title_1}
            </h4>
          )}
          {data && alreadyPaid && alreadyPaid.length > 0 && (
            <div className=" text-xl text-gray-600  x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose">
              <h1 className="text-right mb-8 text-reghda-blue  text-2xl lg:text-4xl 2xl:text-5xl">
                {/* معلومات حول الدورة */}
                {data && data.title_4}
              </h1>

              {data?.course_information && parse(data?.course_information)}
            </div>
          )}

          {((data && !store.isLogged) ||
            (data && alreadyPaid && alreadyPaid.length === 0)) && (
            <div className=" text-xl text-gray-600  x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose">
              {data?.about_course && parse(data?.about_course)}
            </div>
          )}

          {((data && !store.isLogged && data.course_video) ||
            (data &&
              alreadyPaid &&
              alreadyPaid.length === 0 &&
              data.course_video)) && (
            <>
              <h1 className="text-right text-xl font-extrabold text-gray-600 mt-0">
                : الفيديو التعريفي للدورة
              </h1>
              <VideoPlayer url={data.course_video} type="video" />
            </>
          )}
        </div>
      </div>
      {((data && !store.isLogged) ||
        (data && alreadyPaid && alreadyPaid.length === 0)) && (
        <>
          <div className="h-full flex flex-col space-y-6 2xl:space-y-12  font-extrabold leading-loose tracking-widest ">
            <h1 className="text-right text-reghda-blue  text-2xl lg:text-4xl 2xl:text-5xl">
              {/* وصف الدورة */}
              {data && data.title_2}
            </h1>

            <div className=" text-base text-gray-600  x-sm:text-xs x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose">
              {data?.description && parse(data?.description)}
            </div>
          </div>

          <div className="h-full flex flex-col space-y-6 2xl:space-y-12  font-extrabold leading-loose tracking-widest ">
            <h1 className="text-right text-reghda-blue  text-2xl lg:text-4xl 2xl:text-5xl">
              {/* على ماذا ستحصل بعد الشراء */}
              {data && data.title_3}
            </h1>

            <div className="text-base text-gray-600  x-sm:text-xs x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose ">
              {data?.what_you_get && parse(data?.what_you_get)}
            </div>
          </div>
        </>
      )}
      {data && alreadyPaid && alreadyPaid.length > 0 && (
        <div className=" width-80-100 md:w-1/2 mx-auto text-reghda-black ">
          <h1 className="text-center text-reghda-blue text-2xl lg:text-4xl 2xl:text-5xl">
            محتوي الدورة
          </h1>
          <div className="text-white text-right my-8">
            {data.chapters.map((chapter) => (
              <>
                <div
                  key={chapter.id}
                  className="flex justify-end bg-reghda-blue p-4 rounded-xl text-xl lg:text-2xl 2xl:text-3xl mb-2 flex justify-between items-center">
                  <MdArrowDropDownCircle
                    className="cursor-pointer"
                    onClick={() =>
                      showModules === chapter.id
                        ? setShowModules(null)
                        : setShowModules(chapter.id)
                    }
                  />
                  <h1 className="font-extrabold">{chapter.title}</h1>
                </div>

                {showModules === chapter.id &&
                  chapter.modules.map((module) => (
                    <div
                      key={module.id}
                      className="ml-8 my-2 p-4 text-reghda-pink rounded-xl text-base lg:text-xl 2xl:text-2xl border-2 border-reghda-pink font-bold bg-white hover:bg-red-200 hover:text-white transition duration-500 ease-in-out">
                      {module.title}
                    </div>
                  ))}
              </>
            ))}
          </div>
        </div>
      )}
      {data && (
        <Reviews
          setLoading={setLoading}
          isLogged={store.isLogged}
          alreadyPaid={alreadyPaid}
          courseId={data.id}
        />
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
      <LoaderModal modalIsOpen={!data}></LoaderModal>
    </div>
  );
};

export default Details;
