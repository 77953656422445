import React, { useState } from "react";
import { IconContext } from "react-icons";
import { RiAccountCircleLine, RiLockPasswordFill } from "react-icons/ri";
import { IoIosStats } from "react-icons/io";
import { FiLink } from "react-icons/fi";
import { BsWallet } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import Account from "./Account";
import MyLinks from "./MyLinks";
import Balance from "./Balance";
import WithdrawOptions from "./WithdrawOptions";
import useSWR from "swr";
import { fetcherAuth } from "../../../services/fetcher";
import {
  getTheTokenFromStorage,
  getTheUserFromStorage,
} from "../../../services/auth";
import LoaderModal from "../../LoaderModal";
import { toast } from "react-toastify";
import { joinProgram } from "../../../services/affiliate";

const Affiliate = () => {
  const [type, setType] = useState("account");
  const [loading, setLoading] = useState(false);

  const token = getTheTokenFromStorage();

  const { data, mutate, error } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/affiliate/subscriptions/check-subscription`,
      token,
    ],
    fetcherAuth
  );

  return (
    <div
      className={`flex justify-center items-center w-full  md:max-width-1200 mx-auto pb-32 md:pb-0 py-8 md:py-20  mb-24 rtl`}>
      {data && data.status === null && (
        <div className="py-32 flex flex-col items-center justify-center">
          <p className={`text-2xl text-reghda-blue`}>
            إشترك الأن في برنامج تسويق
          </p>
          <div
            onClick={async () => {
              setLoading(true);
              let res = await joinProgram(token);
              console.log("🌵", res);
              if (res && res.status === 200) {
                mutate();
                setLoading(false);
              } else {
                setLoading(false);
                res && toast(`${res.data.message}`);
              }
            }}
            to="/who-is-raghda"
            className="cursor-pointer text-center bg-gradient-to-r from-pink-400 to-indigo-300 text-white mt-6 py-2 px-4 rounded x-sm:text-xs x-sm:w-24 2xl:w-48 2xl:text-3xl">
            إشترك
          </div>
        </div>
      )}

      {data && data.status === "في الإنتظار" && (
        <div className="py-32 flex flex-col items-center justify-center">
          <p className={`text-2xl text-reghda-blue`}>
            تم إرسال طلب إشتراكك للبرنامج
          </p>
          <div
            to="/who-is-raghda"
            className="text-center text-reghda-blue mt-6 py-2 px-4 text-xl">
            حالة الطلب : <span className="text-yellow-400">في الإنتظار</span>
          </div>
        </div>
      )}

      {data && data.status === "مرفوض" && (
        <div className="py-32 flex flex-col items-center justify-center">
          <p className={`text-2xl text-reghda-blue`}>
            تم إرسال طلب إشتراكك للبرنامج
          </p>
          <div
            to="/who-is-raghda"
            className="text-center text-reghda-blue mt-6 py-2 px-4 text-xl">
            حالة الطلب : <span className="text-red-400">مرفوض</span>
          </div>
        </div>
      )}

      {data && data.status === "مقبول" && (
        <div
          className="mx-auto  h-full bg-reghda-blue flex flex-col md:flex-row rounded-lg shadow-2xl "
          style={{ width: "98%" }}>
          <div className="flex-25 h-full text-white py-8 px-4 space-y-6 h-screen-2">
            <div
              onClick={() => {
                setType("account");
              }}
              className={`flex flex-row items-center cursor-pointer gap-2 ${
                type === "account" && `border-white `
              }`}>
              <div>
                <IconContext.Provider
                  value={{ size: "1.6em", style: { color: "#fff" } }}>
                  <IoIosStats></IoIosStats>
                </IconContext.Provider>
              </div>
              <span> الحملات </span>
            </div>
            <div
              onClick={() => {
                setType("mylinks");
              }}
              className={`flex flex-row items-center cursor-pointer gap-2 ${
                type === "mylinks" && `border-white `
              }`}>
              <div>
                <IconContext.Provider
                  value={{ size: "1.6em", style: { color: "#fff" } }}>
                  <FiLink></FiLink>
                </IconContext.Provider>
              </div>
              <span>روابطي </span>
            </div>

            <div
              onClick={() => {
                setType("withdraw-options");
              }}
              className={` flex flex-row items-center cursor-pointer gap-2 ${
                type === "withdraw-options" && `border-white `
              }`}>
              <div>
                <IconContext.Provider
                  value={{ size: "1.6em", style: { color: "#fff" } }}>
                  <GiTakeMyMoney></GiTakeMyMoney>
                </IconContext.Provider>
              </div>
              <span>خيارات السحب</span>
            </div>
            <div
              onClick={() => {
                setType("balance");
              }}
              className={` flex flex-row items-center cursor-pointer gap-2 ${
                type === "balance" && `border-white `
              }`}>
              <div>
                <IconContext.Provider
                  value={{ size: "1.6em", style: { color: "#fff" } }}>
                  <BsWallet></BsWallet>
                </IconContext.Provider>
              </div>
              <span>الرصيد</span>
            </div>
          </div>
          <div className="bg-gray-100 h-full flex-75 h-screen-2">
            <div className="w-11/12 mx-auto">
              {type === "account" && <Account></Account>}
              {type === "mylinks" && <MyLinks></MyLinks>}
              {type === "withdraw-options" && (
                <WithdrawOptions></WithdrawOptions>
              )}
              {type === "balance" && <Balance></Balance>}
            </div>
          </div>
        </div>
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Affiliate;
