import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import Orders from "./Orders";

const Page = () => {
  return (
    <div>
      <Navbar />
      <Orders />
      <Contact />
      <Footer />
    </div>
  );
};

export default Page;
