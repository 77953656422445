import Axios from "axios";

export async function addComment(data) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/comments/blog/all/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteComment(commentId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/comments/blog/all/${commentId}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function editComment(commentId, data) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/comments/blog/all/${commentId}`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addResponse(data) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/comments/blog/answers/`,
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
export async function editResponse(responseId, data) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/comments/blog/answers/${responseId}`,
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteResponse(responseId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/comments/blog/answers/${responseId}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
