import React, { useState, useEffect } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import LoaderModal from "./../LoaderModal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updatePassword, updateProfile } from "../../services/profile";
import { useHistory } from "react-router-dom";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import { getTheUserFromStorage } from "../../services/auth";

const SettingsPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(null);
  const validationSchema = Yup.object({
    givenName: Yup.string().required("هذا الحقل مطلوب"),
    familyName: Yup.string().required("هذا الحقل مطلوب"),
  });

  const validationSchemaPassword = Yup.object({
    oldPassword: Yup.string().required("هذا الحقل مطلوب"),
    newPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
    confirmNewPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف.")
      .oneOf([Yup.ref("newPassword"), null], "خطاء في تأكيد كلمة السر الجديدة"),
  });

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/users/profile/${
      getTheUserFromStorage().user_id
    }`,
    fetcher
  );

  useEffect(() => {
    data && setImage(data.User.image);
  }, [data]);

  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="min-h-80 h-full flex flex-col md:flex-row space-x-4 items-center justify-center p-10 pb-16">
          <div className="h-full w-full md:w-1/2 space-y-6">
            {data && (
              <div className="bg-gray-50 text-right p-12 rounded-2xl shadow-2xl space-y-6 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl">
                <p className="font-extrabold text-reghda-blue">
                  معلومات الشخصية
                </p>
                <Formik
                  initialValues={{
                    givenName: data.User.given_name,
                    familyName: data.User.family_name,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    console.log(values, "👀");
                    setLoading(true);
                    const formData = new FormData();
                    formData.append(`given_name`, values.givenName);
                    formData.append(`family_name`, values.familyName);
                    if (image && typeof image !== "string") {
                      formData.append(`image`, image);
                    }
                    let res = await updateProfile(
                      getTheUserFromStorage().user_id,
                      formData
                    );
                    if (res.status === 200) {
                      setLoading(false);
                      mutate(
                        `https://ragdasairafi369.smartiniaclient.com/users/profile/${
                          getTheUserFromStorage().user_id
                        }`
                      );
                    } else {
                      setLoading(false);
                      alert("something wrong,retry");
                    }
                    setSubmitting(false);
                  }}>
                  <Form className="flex flex-col w-full px-0 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right md:text-xl 2xl:text-4xl">
                    <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
                      <div className="w-full flex flex-col items-center justify-between space-y-4">
                        <Field
                          name="givenName"
                          type="text"
                          defaultValue={data.User.given_name}
                          placeholder="الإسم"
                          class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
                        />
                        <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                          <ErrorMessage name="givenName" />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
                      <div className="w-full flex flex-col items-center justify-between space-y-4">
                        <Field
                          name="familyName"
                          defaultValue={data.User.family_name}
                          type="text"
                          placeholder="اللقب"
                          class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
                        />
                        <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                          <ErrorMessage name="familyName" />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="flex flex-row items-center justify-end">
                      <div className="flex flex-row items-center justify-around space-x-5">
                        <div>
                          <input
                            onChange={(event) => {
                              if (event.target.files[0]) {
                                setImage(event.target.files[0]);
                              }
                            }}
                            id="upload-img"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <div
                            onClick={() => {
                              document.querySelector("#upload-img").click();
                            }}
                            className="text-reghda-blue font-bold text-right cursor-pointer">
                            تحميل صورة جديدة
                          </div>
                        </div>
                        <img
                          className="rounded-full x-sm:w-12 x-sm:h-12  md:w-20 md:h-20 2xl:w-28 2xl:h-28 picfit"
                          alt="logo"
                          src={
                            image
                              ? typeof image === "string"
                                ? image
                                : URL.createObjectURL(image)
                              : "/images/admin/default-user.png"
                          }
                        />
                      </div>
                    </fieldset>
                    <div className="flex flex-row items-center justify-center">
                      <button
                        type="submit"
                        className="bg-reghda-blue text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
                        حفظ
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            )}

            {getTheUserFromStorage().auth_provider === "email" && (
              <div className="bg-gray-50 text-right p-12 rounded-2xl shadow-2xl space-y-6 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl">
                <p className="font-extrabold text-reghda-blue">
                  تغيير كلمة السر
                </p>
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                  }}
                  validationSchema={validationSchemaPassword}
                  onSubmit={async (values, { setSubmitting }) => {
                    setLoading(true);
                    let res = await updatePassword({
                      user: getTheUserFromStorage().user_id,
                      old_password: values.oldPassword,
                      new_password: values.newPassword,
                    });
                    if (res.status === 200) {
                      setLoading(false);
                      history.push("/");
                    } else {
                      setLoading(false);
                      alert("something wrong,retry");
                    }
                    setSubmitting(false);
                  }}>
                  <Form className="flex flex-col w-full px-0 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right md:text-xl 2xl:text-4xl">
                    <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
                      <div className="w-full flex flex-col items-center justify-between space-y-4">
                        <Field
                          name="oldPassword"
                          type="password"
                          placeholder="كلمة السر القديمة"
                          class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl  outline-none focus:outline-none"
                        />
                        <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                          <ErrorMessage name="oldPassword" />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
                      <div className="w-full flex flex-col items-center justify-between space-y-4">
                        <Field
                          name="newPassword"
                          type="password"
                          placeholder="كلمة السر الجديدة"
                          class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl  outline-none focus:outline-none"
                        />
                        <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                          <ErrorMessage name="newPassword" />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-x-3 lg:space-x-6 2xl:space-x-12">
                      <div className="w-full flex flex-col items-center justify-between space-y-4">
                        <Field
                          name="confirmNewPassword"
                          type="password"
                          placeholder="تأكيد كلمة السر الجديدة"
                          class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-3 2xl:p-6 pr-6 rounded-2xl outline-none focus:outline-none"
                        />
                        <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                          <ErrorMessage name="confirmNewPassword" />
                        </div>
                      </div>
                    </fieldset>

                    <div className="flex flex-row items-center justify-center">
                      <button
                        type="submit"
                        className="bg-reghda-blue text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
                        حفظ
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            )}
          </div>
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default SettingsPage;
