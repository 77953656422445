import axios from "axios";

export async function reservateSession(session, user) {
  try {
    const response = await axios.post(
      "https://ragdasairafi369.smartiniaclient.com/appointments/booking",
      { session, user }
    );
    return response;
  } catch (error) {
    return { error };
  }
}

export async function deleteReservation(id) {
  try {
    const response = await axios.post(
      "https://ragdasairafi369.smartiniaclient.com/appointments/cancel",
      { appointment: id }
    );
    return response;
  } catch (error) {
    return { error };
  }
}
