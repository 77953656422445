import React, { useState } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";

const WhyToChooseRaghda = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="h-full p-16 x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600 font-extrabold x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          <h1 className="font-extrabold text-center x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
            لماذا تختار رغده صيرفي مدربة لك ؟
          </h1>
          <div className="text-right x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl leading-loose space-y-6">
            <p className="font-medium leading-loose break-words">
              ـ لديها خبرة في التدريب أكثر من عشر سنوات
            </p>
            <p className="font-medium leading-loose break-words">
              ـ حضرت العديد من الدورات وورش العمل{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ دربت أكثر من أربعة ألاف متدربة لأكثر من ٢١ دفعة على مدار العشر
              سنوات
            </p>
            <p className="font-medium leading-loose break-words">
              ـ ذات قيادة مميزة وتنظيم شامل والقدرة العالية على إدارة المجموعات
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تمتلك العطاء العالي
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تقدم مادة علمية متقنة وذات جودة وشاملة
            </p>
            <p className="font-medium leading-loose break-words">
              ـ لديها أسلوب ممتع ومفيد حازمة وبنفس الوقت مرنة ، حازمة وبنفس
              الوقت تشعر بحبها لك وحنانها عليك ومدى حرصها على إفادتك{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تتمتلك مصداقية عالية فيما تقدم وبطريقة تعاملها الراقية
              والمتواضعة مع عملائها{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ ذات أسلوب سلس وبسيط ومفيد مناسب لجميع الشخصيات
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تتمتلك مهارة عالية في سرد القصص الحقيقية المؤثرة والتجارب
              الشخصية المفيدة وتدعيم محتواها وتبسيطه لتجربته على الواقع بشكل سهل
              ومناسب{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تلديها تفاعل مميز ورائع مع الجمهور وتمتلك حس فكاهي مميز ومؤثر{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تمتطورة دائماً وتتعلم كل جديد فحتى المتدربات السابقات يعدن
              الإشتراك والدخول معها لتأكدهن من الإستفادة منها ومن خبراتها وتطور
              علمها{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تمتلك نظرة عميقة لعملاءها وتساعدهم في تطور ماينقصهم ومايحتاجونه
            </p>
            <p className="font-medium leading-loose break-words">
              ـ لديها خبرة عالية في جوانب الحياة نظراً لتجاربها الشخصية التي
              علمتها الكثير من الدروس التي تحب دائماً نقلها للعملاء{" "}
            </p>
            <p className="font-medium leading-loose break-words">
              ـ تقدم برامجها وعملها بحب وشغف ومتعة وإصرار لإفادة العملاء وتحسين
              حياتهم{" "}
            </p>
          </div>
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default WhyToChooseRaghda;
