import React from "react";
import { Link, useHistory } from "react-router-dom";

const CourseCard = ({ url, name, id, slug }) => {
  const history = useHistory();

  return (
    <div
      onClick={() => {
        history.push(`/course/${slug}`, {
          data: {
            chapterId: null,
            moduleId: null,
          },
        });
      }}
      className="cursor-pointer w-full h-full"
    >
      <div className="w-full h-full flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
        <img
          src={url}
          alt="course-pic"
          className="w-full h-full object-cover shadow-2xl mb-4"
        />
        <h6>{name}</h6>
      </div>
    </div>
  );
};

export default CourseCard;
