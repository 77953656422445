import React from "react";
import { Link, useLocation } from "react-router-dom";

const PayedCourseCard = ({
  course,
  name,
  slug,
  id,
  price,
  url,
  description,
  alreadyPaid,
}) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={{
        pathname: `/payed-courses/course-detail/${slug}`,
        detail: {
          id: id,
          slug: slug,
          name: name,
          description: description,
          price: price,
          url: url,
          alreadyPaid: alreadyPaid,
        },
      }}
      className="cursor-pointer w-full h-full"
    >
      <div className="w-full h-full flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
        <img
          // src={`/images/payed/${number}.png`}
          src={url}
          alt="course-pic"
          className="w-full h-full object-cover shadow-2xl mb-4"
        />
        <h6 className="text-center x-sm:text-sm sm:text-lg lg:text-xl 2xl:text-2xl">
          {name}
        </h6>
        {pathname === "/payed-courses" && (
          <p className="font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl border-1 p-2 rounded-xl">{`السعر : ${price} ريال`}</p>
        )}
        {/* {pathname === "/payed-courses" && course.show_low_income_price && (
          <p className="text-reghda-blue font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl border-1 p-2 rounded-xl">{`${course.low_income_name} : ${course.low_income_price} ريال`}</p>
        )} */}
      </div>
    </Link>
  );
};

export default PayedCourseCard;
