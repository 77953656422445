import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import { IconContext } from "react-icons";
import { FaUserAlt } from "react-icons/fa";

import useSWR from "swr";
import { fetcher } from "../../services/fetcher";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

import Reviews from "./Reviews";

const BlogContentPage = () => {
  const { slug } = useParams();
  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/blog/post/${slug}`,
    fetcher
  );

  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="min-h-80 w-9/12 mx-auto mt-8 space-y-6 x-sm:py-5 md:py-10 2xl:py-16">
          <div className="w-full text-right text-reghda-blue x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
            <h1> {data && data.title} </h1>
          </div>
          <div className="w-full flex flex-row items-end justify-end space-x-4 text-reghda-blue x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl">
            {data && (
              <p className="text-gray-300">
                {data.created_at.substring(0, 10)}
              </p>
            )}
            {data && (
              <p className="font-extrabold">
                {`${data.author.family_name} ${data.author.given_name}`}
              </p>
            )}

            <div className="x-sm:text-sm sm:text-sm lg:text-base 2xl:text-lg">
              <IconContext.Provider
                value={{ size: "1.8em", style: { color: "#91b6c6" } }}>
                <FaUserAlt></FaUserAlt>
              </IconContext.Provider>
            </div>
          </div>
          {data && <img className="w-full" src={data.image} alt={"desc"} />}

          {data?.body && parse(data?.body)}

          {data && <Reviews slug={slug} blogId={data.id} />}
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default BlogContentPage;
