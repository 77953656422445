import React, { useState, useContext, useEffect } from "react";
import { storeContext } from "./../../../global/store";
import {
  useParams,
  useLocation,
  useHistory,
  Link,
  Redirect,
} from "react-router-dom";
import LoaderModal from "../../LoaderModal";
import { getTheUserFromStorage } from "./../../../services/auth";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import BookViewer from "./BookViewer";
import { addToCart } from "../../../services/cart";
import Reviews from "./Reviews";

import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";

const Detail = () => {
  let { type, slug } = useParams();
  const { store, setStore } = useContext(storeContext);
  let history = useHistory();
  let location = useLocation();
  const book = location.state.book;
  const [loading, setLoading] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState(null);

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/books/details/${slug}`,
    fetcher
  );

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        `https://ragdasairafi369.smartiniaclient.com/students/mybooks/${data.id}`,
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setAlreadyPaid(res.data);
      /* setAlreadyPaid(["aa"]); */
      return res;
    }
    data && store.isLogged && fetch();
  }, [data]);

  if (type === "free" && !store.isLogged) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <div className="md:space-y-16">
      <div className="x-sm:h-full flex flex-col md:flex-row items-center justify-around md:space-x-24">
        <div className="md:pl-32 x-sm:p-24 2xl:p-48 x-sm:w-full sm:w-2/3 md:w-1/2">
          {data && <img alt="some-book" src={data.image}></img>}
          {/* {book && <img alt="some-book" src={book.image}></img>} */}
          {((!store.isLogged && data && type === "paid") ||
            (store.isLogged &&
              type === "paid" &&
              data &&
              alreadyPaid &&
              alreadyPaid.length === 0)) && (
            <div className="mt-5 flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
              <p className="font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl border-1 p-2 rounded-xl">{`السعر : ${data.price} ريال`}</p>
            </div>
          )}
        </div>
        <div className="text-center x-sm:w-full md:w-2/3 px-4 md:px-20 flex flex-col space-y-8 2xl:space-y-16 items-center justify-around">
          {data && (
            <h1 className="text-reghda-blue font-extrabold x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-8xl">
              {data.name}
            </h1>
          )}
          {/* <div className="text-right text-gray-600 font-extrabold x-sm:text-xs x-sm:leading-loose md:text-base md:leading-loose lg:text-xl lg:leading-loose 2xl:text-4xl 2xl:leading-loose">
            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا
            النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
            من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا
            كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد
            الفقرات كما تريد
          </div> */}
          {data && (
            <div className="text-right text-gray-600 font-extrabold x-sm:text-xs x-sm:leading-loose md:text-base md:leading-loose lg:text-xl lg:leading-loose 2xl:text-4xl 2xl:leading-loose">
              {data.description}
            </div>
          )}
          <div className="flex flex-row space-x-6 2xl:space-x-12">
            {store.isLogged &&
              type === "paid" &&
              alreadyPaid &&
              alreadyPaid.length < 1 && (
                <button
                  onClick={async () => {
                    // add to cart
                    setLoading(true);
                    let res = await addToCart({
                      book: data.id,
                      user: getTheUserFromStorage().user_id,
                    });
                    if (res.status === 201) {
                      setLoading(false);
                      ReactPixel.track("AddToCart", {
                        content_ids: [data.id],
                        content_name: data.name,
                        content_type: "book",
                        currency: "SAR",
                        value: data.price,
                      });
                      history.push("/cart");
                    } else {
                      setLoading(false);
                      toast("something wrong,retry");
                    }
                  }}
                  className="bg-reghda-blue text-gray-700 p-1 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl 2xl:text-4xl outline-none focus:outline-none">
                  أضف إلى السلة
                </button>
              )}
            {!store.isLogged && type === "paid" && (
              <Link
                to="/login"
                className="bg-reghda-pink text-gray-700 p-1 2xl:p-3 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl 2xl:text-4xl">
                تسجيل للشراء
              </Link>
            )}
          </div>
        </div>
      </div>
      {data && type === "free" && <BookViewer url={data.book}></BookViewer>}
      {data &&
        type === "paid" &&
        store.isLogged &&
        alreadyPaid &&
        alreadyPaid.length > 0 && <BookViewer url={data.book}></BookViewer>}

      {data && (
        <Reviews
          setLoading={setLoading}
          isLogged={store.isLogged}
          alreadyPaid={alreadyPaid}
          type={type}
          bookId={data.id}
        />
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Detail;
