import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { storeContext } from "./../global/store";
import ProtectedRoute from "./ProtectedRoute";
import Signup from "./../components/Signup";
import Login from "./../components/Login";
import Homepage from "./../components/homepage/Page";
import FreeCoursePage from "./../components/free-course/Page";
import MyCoursePage from "./../components/my-courses/Page";
import PayedCoursePage from "./../components/payed-course/Page";
import CourseDetailPage from "./../components/course-detail/Page";
import FollowCourseDetailPage from "./../components/follow-course-detail/Page";
import CourseStudyPage from "./../components/course-study/Page";
import FollowCourseStudyPage from "./../components/follow-course-study/Page";
import ContactUs from "./../components/contact-us/ContactUs";
import PeopleSaid from "./../components/people-said/PeopleSaid";
import AboutRaghda from "./../components/about-raghda/AboutRaghda";
import WhyToChooseRaghda from "./../components/about-raghda/WhyToChooseRaghda";
import CartPage from "./../components/cart/Page";
import CheckOutPage from "./../components/check-out/Page";
import SettingsPage from "./../components/settings/Page";
import PrivacyPolicyPage from "./../components/policy-pages/PrivacyPolicy";
import RefundPolicyPage from "./../components/policy-pages/RefundPolicy";
import QuestionsPage from "./../components/policy-pages/Questions";
import CheckOutVerifyPage from "./../components/check-out-verify/Page";
import CheckOutSessionVerifyPage from "../components/check-out-session-verify/Page";
import ConditionsPage from "../components/policy-pages/Conditions";
import BooksPage from "./../components/books/Page";
import BookDetailPage from "./../components/book-detail-page/Page";
import MyBooksPage from "./../components/my-books/Page";
import UserActivation from "./../components/UserActivation/UserActivation";
import UserReactivation from "./../components/UserActivation/UserReactivation";
import ResetPassword from "./../components/ResetPassword/Page";
import Schedule from "./../components/scheduling-page/Page";
import Consulting from "./../components/consulting-page/Page";
import MyConsultations from "./../components/my-consultations/Page";
import CheckOutConsulting from "./../components/check-out-consultation/Page";
import Orders from "./../components/my-orders/Page";
import ScrollToTop from "./scrollToTop";
import Blogs from "./../components/blogs/Page";
import BlogContent from "./../components/blog-content/Page";
import Certificate from "./../components/certificate/Page";
import Affiliate from "./../components/Affiliate/Page";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const App = () => {
  const screenWidth = useWindowWidth();
  const { store } = useContext(storeContext);

  return (
    <div className={`${screenWidth >= 768 ? "font-bahij" : "font-arial"}`}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route
            path="/blogs"
            exact
            render={(props) => <Blogs {...props}></Blogs>}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/blogs/:slug"
            exact
            render={(props) => <BlogContent {...props}></BlogContent>}
          ></ProtectedRoute>
          <Route
            path="/consulting"
            exact
            render={(props) => <Consulting {...props}></Consulting>}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/my-consultations"
            exact
            render={(props) => <MyConsultations {...props}></MyConsultations>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/my-orders"
            exact
            render={(props) => <Orders {...props}></Orders>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/checkout-consultation"
            exact
            render={(props) => (
              <CheckOutConsulting {...props}></CheckOutConsulting>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/schedule"
            exact
            render={(props) => <Schedule {...props}></Schedule>}
          ></ProtectedRoute>
          <Route
            path="/user_activation"
            exact
            render={(props) => <UserActivation {...props}></UserActivation>}
          ></Route>
          <Route
            path="/user_reactivation"
            exact
            render={(props) => <UserReactivation {...props}></UserReactivation>}
          ></Route>
          <Route
            path="/reset_password"
            exact
            render={(props) => <ResetPassword {...props}></ResetPassword>}
          ></Route>
          <Route
            path="/reset_password/:userId/:token"
            exact
            render={(props) => <ResetPassword {...props}></ResetPassword>}
          ></Route>
          <Route
            path="/signup"
            exact
            render={(props) => <Signup {...props}></Signup>}
          ></Route>
          <Route
            path="/login"
            exact
            render={(props) => <Login overview {...props}></Login>}
          ></Route>
          <Route
            path="/"
            exact
            render={(props) => <Homepage {...props}></Homepage>}
          ></Route>
          <Route
            path="/people-said"
            exact
            render={(props) => <PeopleSaid {...props}></PeopleSaid>}
          ></Route>
          <Route
            path="/who-is-raghda"
            exact
            render={(props) => <AboutRaghda {...props}></AboutRaghda>}
          ></Route>
          <Route
            path="/why-to-choose-raghda"
            exact
            render={(props) => (
              <WhyToChooseRaghda {...props}></WhyToChooseRaghda>
            )}
          ></Route>
          <Route
            path="/questions"
            exact
            render={(props) => <QuestionsPage {...props}></QuestionsPage>}
          ></Route>
          <Route
            path="/return"
            exact
            render={(props) => <RefundPolicyPage {...props}></RefundPolicyPage>}
          ></Route>
          <Route
            path="/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage {...props}></PrivacyPolicyPage>
            )}
          ></Route>
          <Route
            path="/rules"
            exact
            render={(props) => <ConditionsPage {...props}></ConditionsPage>}
          ></Route>
          <Route
            path="/contact-us"
            exact
            render={(props) => <ContactUs {...props}></ContactUs>}
          ></Route>
          <Route
            path="/books"
            exact
            render={(props) => <BooksPage {...props}></BooksPage>}
          ></Route>
          <Route
            path="/books/:type/:slug"
            exact
            render={(props) => <BookDetailPage {...props}></BookDetailPage>}
          ></Route>
          <Route
            path="/free-courses"
            exact
            render={(props) => <FreeCoursePage {...props}></FreeCoursePage>}
          ></Route>

          <Route
            path="/payed-courses"
            exact
            render={(props) => <PayedCoursePage {...props}></PayedCoursePage>}
          ></Route>
          <Route
            path="/payed-courses/course-detail/:slug"
            exact
            render={(props) => <CourseDetailPage {...props}></CourseDetailPage>}
          ></Route>
          <Route
            path="/follow-courses/course-detail/:slug"
            exact
            render={(props) => (
              <FollowCourseDetailPage {...props}></FollowCourseDetailPage>
            )}
          ></Route>
          <Route
            path="/certificate"
            exact
            render={(props) => <Certificate {...props}></Certificate>}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/settings"
            exact
            render={(props) => <SettingsPage {...props}></SettingsPage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/cart"
            exact
            render={(props) => <CartPage {...props}></CartPage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/check-out"
            exact
            render={(props) => <CheckOutPage {...props}></CheckOutPage>}
          ></ProtectedRoute>
          <Route
            isLogged={store.isLogged}
            path="/check-out/verify"
            exact
            render={(props) => (
              <CheckOutVerifyPage {...props}></CheckOutVerifyPage>
            )}
          ></Route>
          <Route
            isLogged={store.isLogged}
            path="/check-out/session/verify"
            exact
            render={(props) => (
              <CheckOutSessionVerifyPage {...props}></CheckOutSessionVerifyPage>
            )}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/my-courses"
            exact
            render={(props) => <MyCoursePage {...props}></MyCoursePage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/my-books"
            exact
            render={(props) => <MyBooksPage {...props}></MyBooksPage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/course/:slug"
            exact
            render={(props) => <CourseStudyPage {...props}></CourseStudyPage>}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/follow-course/:slug"
            exact
            render={(props) => (
              <FollowCourseStudyPage {...props}></FollowCourseStudyPage>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/affiliate"
            exact
            render={(props) => <Affiliate {...props}></Affiliate>}
          ></ProtectedRoute>
          <Redirect to="/"></Redirect>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
