import axios from "axios";

export async function sendEmailUserActivation(email) {
  try {
    const response = await axios.post(
      "https://ragdasairafi369.smartiniaclient.com/users/resend-email",
      {
        email,
      }
    );
    return response;
  } catch (error) {
    return { error };
  }
}
