import React, { useState } from "react";
import useSWR, { mutate } from "swr";
import { getTheTokenFromStorage } from "../../../services/auth";
import { fetcherAuth } from "../../../services/fetcher";
import ModalWithdraw from "./ModalWithdraw";

const Balance = () => {
  const token = getTheTokenFromStorage();
  const [openModal, setOpenModal] = useState(false);

  const { data, error } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/balance/users/get`,
      token,
    ],
    fetcherAuth
  );
  const { data: withdrawRequests, mutate: mutateRequest } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/withdraw/users/request/get`,
      token,
    ],
    fetcherAuth
  );

  const { data: withdrawOptions } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/withdraw/users/options/get`,
      token,
    ],
    fetcherAuth
  );

  if (!data)
    return (
      <div className="text-center my-8 p-8 text-reghda-blue border border-reghda-blue rounded-md">
        ليس لديك رصيد نشط
      </div>
    );
  if (data)
    return (
      <div className="w-full h-full px-4 py-6 overflow-y-auto space-y-4">
        <h1 className="text-2xl font-semibold text-reghda-blue">رصيدك</h1>
        <div className="bg-reghda-blue p-4 rounded-md text-white font-bold w-1/3 mx-auto space-y-4">
          <div className="flex  rounded-xl  justify-between">
            <p className="w-24"> رصيدك المتاح </p>
            <p>
              {data?.currency_code} {data?.available_amount}
            </p>
          </div>
          <div className="flex justify-between rounded-xl  ">
            <p className="w-24"> حالة الرصيد </p>
            <p>{data?.status}</p>
          </div>
        </div>
        <h1 className="text-2xl font-semibold text-reghda-blue">
          طلبات السحب الخاصة بي
        </h1>
        {withdrawRequests?.map((item) => (
          <fieldset className="border rounded-md p-2 border-reghda-blue box-shadow-custom">
            <legend className="px-2">طلب سحب #{item.id}</legend>

            <fieldset className="p-4 border rounded-md ">
              <legend className="px-2"> معلومات عن خيار السحب</legend>
              <div className="space-y-2">
                <div className="flex gap-2">
                  <p className="w-32"> إسم الحساب</p>
                  <p>{item?.withdraw_option?.account_name}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-32">نوع الحساب</p>
                  <p>{item?.withdraw_option?.account_type}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-32">رقم حساب</p>
                  <p>{item?.withdraw_option?.account_number}</p>
                </div>
              </div>
            </fieldset>
            <fieldset className="p-4 border rounded-md ">
              <legend className="px-2"> معلومات عن السحب</legend>
              <div className="space-y-2">
                <div className="flex gap-2">
                  <p className="w-32">مبلغ</p>
                  <p>{item?.amount}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-32"> الحالة</p>
                  <p>{item?.status}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-32"> تاريخ الإنشاء</p>
                  <p>{item?.created_at?.slice(0, 10)}</p>
                </div>
              </div>
            </fieldset>
          </fieldset>
        ))}
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-reghda-blue rounded-md text-white"
            onClick={() => setOpenModal(!openModal)}>
            إضافة طلب سحب
          </button>
        </div>
        <ModalWithdraw
          withdrawOptions={withdrawOptions}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          balanceData={data}
          mutate={mutateRequest}
        />
      </div>
    );
};

export default Balance;
