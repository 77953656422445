import React from "react";
import Navbar from "./../Navbar";
import BookList from "./content/BookList";
import Contact from "./../Contact";
import Footer from "./../Footer";

const MyBooksPage = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <BookList title="قائمة كتبي"></BookList>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default MyBooksPage;
