import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaUserAlt } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";

const BlogCard = ({ blog }) => {
  const history = useHistory();

  return (
    <div
      onClick={() => history.push(`/blogs/${blog.slug}`)}
      className="w-full h-full cursor-pointer x-sm:p-1 sm:p-4 space-y-2"
    >
      <div className="w-full h-full x-sm:p-2 md:p-4 2xl:p-6 flex flex-col items-center space-y-4 bg-white rounded-xl shadow-2xl text-reghda-blue">
        {blog.image && (
          <img
            className="object-cover w-full h-full rounded-xl"
            src={blog.image}
            alt="blog-pic"
          />
        )}
        <h6 className="x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold text-center">
          {blog.title}
        </h6>
        <div className="w-full flex flex-row items-center justify-start space-x-2">
          <span>
            <IconContext.Provider
              value={{ size: "1em", style: { color: "#91b6c6" } }}
            >
              <BsArrowLeft></BsArrowLeft>
            </IconContext.Provider>
          </span>
          <span className="x-sm:text-xs sm:text-sm lg:text-base 2xl:text-lg">
            لقراءة المزيد
          </span>
        </div>
        <div className="w-full flex flex-row items-end justify-end space-x-4 x-sm:text-xs 2xl:text-base">
          <p className="text-gray-300">{blog.created_at.substring(0, 10)}</p>
          <p className="font-extrabold">{blog.author.given_name}</p>
          <div className="x-sm:text-xs sm:text-sm lg:text-base 2xl:text-lg">
            <IconContext.Provider
              value={{ size: "1.4em", style: { color: "#91b6c6" } }}
            >
              <FaUserAlt></FaUserAlt>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
