import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../../services/fetcher";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { useHistory } from "react-router-dom";

const AboutFive = () => {
  const history = useHistory();

  const screenWidth = useWindowWidth();

  const [images, setImages] = useState(["images/about-5.png"]);
  const [current, setCurrent] = useState(0);

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/paid/`,
    fetcher
  );

  useEffect(() => {
    let newArr = [];
    data &&
      data.map((course) => {
        newArr.push(course.picture);
        return course;
      });

    data && data.length && setImages(newArr);
  }, [data]);

  const next = () => {
    if (current < images.length - 1) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };

  const previous = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(images.length - 1);
    }
  };

  return (
    <div className="h-80 flex flex-col-reverse items-center space-y-10 md:flex-row">
      <IconContext.Provider
        value={{
          size: screenWidth >= 768 ? "2.5em" : "1.5em",
          style: { color: "#c3dafe" },
        }}>
        <div className="flex-1 text-right flex flex-col text-gray-600">
          <div className="text-center">
            <h1
              onClick={() => {
                history.push("/payed-courses");
              }}
              className="cursor-pointer x-sm:text-lg lg:text-5xl 2xl:text-7xl text-indigo-200 text-center">
              القسم المدفوع
            </h1>
          </div>
          <div className="x-sm:text-xs md:text-base lg:text-2xl lg:tracking-wide lg:leading-loose x-sm:p-6 sm:px-20 md:py-6 2xl:p-12 2xl:text-4xl 2xl:tracking-wide 2xl:leading-loose">
            <p>
              يمكنك ان أن تستثمر في ذاتك وتساعد نفسك أكثر وذلك باقتناء دورات
              غنية جدا بالمعلومات هناك دورات سنوية و أخرى شهرية و هناك أمسيات
              متنوعة ستساعدك على التعرف على بعض المفاهيم المعينة في حياتك، كما
              يمكنك استفادة من الدخول لجروبات خاصة و تصنع لنفسك بيئة داعمة و
              مشجعة لك
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div onClick={previous} className="cursor-pointer">
            <AiFillLeftCircle></AiFillLeftCircle>
          </div>
          <div
            onClick={() => {
              history.push("/payed-courses");
            }}
            className="cursor-pointer flex-2">
            {data && (
              <img
                src={images[current]}
                alt="Layer"
                className="x-sm:w-36 x-sm:h-36 md:w-64 md:h-64 lg:w-about-pics lg:h-about-pics 2xl:w-about-pics-xl 2xl:h-about-pics-xl shadow-2xl"
              />
            )}
            {!data && (
              <img
                src={images[current]}
                alt="Layer"
                className="x-sm:w-36 x-sm:h-36 md:w-64 md:h-64 lg:w-about-pics lg:h-about-pics 2xl:w-about-pics-xl 2xl:h-about-pics-xl"
              />
            )}
          </div>
          <div onClick={next} className="cursor-pointer">
            <AiFillRightCircle></AiFillRightCircle>
          </div>
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default AboutFive;
