import React, { useState } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";

const AboutRaghda = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute ">
        <Navbar></Navbar>
        <div className="h-full text-center px-6 md:px-8 lg:px-16 py-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600">
          {/* <h1 className="font-extrabold text-2xl lg:text-3xl 2xl:text-4xl">
            من هي رغده صيرفي
          </h1> */}
          <div
            dir="rtl"
            className=" flex flex-col items-start justify-start gap-y-1 md:gap-y-4 text-right text-xl md:text-2xl 2xl:text-3xl leading-loose w-full min-h-screen">
            {/* <span className="font-medium leading-loose break-words">
              🔺 مدربة حياة
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 كاتبة وصانعة محتوى
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس ولايف كوتشنج باعتماد من icf
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 مدربة يوغا الضحك
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 مدربة يوغا 200 ساعة
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 يوغا ثيرابي 300 ساعة 
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس تقنية theta healing ( 9 مستويات )
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس تقنية العلاج الشعوري peat
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس بعلاج الرايك للتحرر من  الصدمات
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس تقنية الإتزان العاطفي EBT
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس الطب الشعوري العضوي
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس الطب الشعوري النفسي
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ميسرة وممارس تقنية Access bars
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس تقنية body process
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس تقنية facelift
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس للعلاج بحقل الفكرة tft
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس الطب الشعوري النفسي
            </span>
            <span className="font-medium leading-loose break-words">
              🔺 ممارس الطب الشعوري العضوي
            </span>
            <p className="font-medium leading-loose break-words">
              حضرت العديد من الورش والبرامج والدورات والدبلومات في مختلف جوانب
              الحياة ولدي خبرة ومعرفة عن الكثير من أسرار النجاح في كل جانب من
              جوانب الحياة بخبرة من تجاربي والتحديات التي واجهتني وعلم تعلمته
              خلال رحلة التشافي والتطور
            </p>
            <p className="font-medium leading-loose break-words">
              وحاصلة على العديد من الشهادات بتطوير الذات والتشافي
            </p>
            <p className="font-medium leading-loose break-words">
              ابتكرت تقديم دورات عبر الواتس اب من عام 2010
            </p>
            <p className="font-medium leading-loose break-words">
              رُشحت عام ٢٠١٨ ضمن ٧٧ مرشح ومرشحة بمؤتمر قدوة الذي يهتم بإسقاط
              الضوء على الشخصيات الناجحة والمؤثرة والملهمة
            </p>
            <p className="font-medium leading-loose break-words">
              مؤسسة براند عطور رغد الحب يباع أون لاين عام 2018 وقد بيع منه ألاف
              العبوات ولله الحمد
            </p>
            <p className="font-medium leading-loose break-words">
              قدمت اول برنامج للتشافي مطول مدته ٩٠ يوما من عام 2010 للنساء وتم
              تدريب 4500مشتركة فيه وهو برنامج كوني ملكة الحب الذي تم تقديمه 24
              دفعة على مدار احدى عشر عاما
            </p>
            <p className="font-medium leading-loose break-words">
              أسست موقع كوني أنتِ عام 2014 خدمة غير ربحية كانت تحوي الاف
              المشتركات من شتى انحاء العالم
            </p>
            <p className="font-medium leading-loose break-words">
              😊 بدأت رحلتي من عام 2009  تحررت من الكثير من مخاوفي وصدماتي وأعيش
              حياتي وحقيقتي بكل حب وسهولة ولله الحمد بأدوات وتقنيات رهيبة وسهلة
              وانا هنا حتى أدعمك تصنع واقع جديد مختلف لحياتك حتى تعيش الحياة
              اللي تشبه روحك في كل جوانب حياتك من خلال : 
            </p>
            <span className="font-medium leading-loose break-words">
              💎 برامج التشافي المطولة منها برنامج كوني ملكة الحب حالياً للنساء
              فقط
            </span>
            <span className="font-medium leading-loose break-words">
              💎 دورات وورش تدريبية مبسطة وقصيرة ومتخصصة منها ورشة زوجة
              استثنائية ، ورشة انا معاك (لحب الذات ) ، دورة لذة الروح ( تأملات
              روحانية في حب الله ) 
            </span>
            <span className="font-medium leading-loose break-words">
              💎 امسيات مجانية خفيفة منها ميلاد النور في رمضان ، ورشة أنا معاك (
              حب الذات )
            </span>
            <span className="font-medium leading-loose break-words">
              💎 روايات نجاح تدريبية حقيقية الكترونية لإحدى مشتركات برامجي
              التدريبية المطولة منها رواية وانتصر الحب ، شموخ ملكة ، سامحتك
              فسامحني ، واكتشفت اجمل حب ، الرجل الطفل خان الاميرة
            </span>
            <span className="font-medium leading-loose break-words">
              💎 حصص يوغا الضحك حتى تعيد توازنك الشعوري وتدعم البهجة في حياتك
            </span>
            <span className="font-medium leading-loose break-words">
              💎 الجلسات الفردية الخاصة عبر الزوم منها جلسة الكوتشنج والاستشارة
              ، جلسة كوتشنج الضحك للتحرر بالضحك ، جلسة فك الصدمات بتقنية الرايك
              ، جلسة تقنية peat لفك الصدمات ، جلسة ثيتا للتحرر من المعتقدات
              والمعيقات والمخاوف والبرمجات المعيقة 
            </span>
            <span className="font-medium leading-loose break-words flex flex-col">
              💎 الجلسات الفردية الخاصة مباشر حضورية لتفريغ الجسم من كل الطاقات
              المخزنة فيه المخاوف والصدمات والمشاعر واعادة التوازن للجسم 
              <span className="font-medium leading-loose break-words">
                جلسة Access bars 
              </span>
              <span className="font-medium leading-loose break-words">
                جلسة body process
              </span>
              <span className="font-medium leading-loose break-words">
                جلسة facelift
              </span>
            </span>

            <span className="font-medium leading-loose break-words">
              💎 حساباتي المجانية عبر السوشال ميديا اشاركك فيها افكاري ، تأملاتي
              ،  بعض من خبراتي ومعلوماتي وتجاربي الخاصة 
            </span>
            <p className="font-medium leading-loose break-words">
              ✨اقدم لك عطور ( رغد الحب )عطور فرنسية صنعت بحب تقدم بطابع غير
              تقليدي اصلية ذات جودة عالية ومركبات ذات مقاييس عالمية لاتسبب
              الحساسية ، مركزة ، تحتوي على الفيرمونات (للجاذبية والإثارة ) تم
              بيع منه ألاف العبوات ولله الحمد 
            </p>
            <p className="font-medium leading-loose break-words">
              🤍 للتسجيل ببرامجنا او متابعة حساباتنا او لشراء عطورنا يرجى زيارة
              الرابط التالي: 
            </p>
            <a
              href="https://linktr.ee/ragdasairafi369"
              target="_blank"
              rel="noreferrer"
              className="">
              <span className="font-medium leading-loose break-words text-blue-500 hover:underline">
                https://linktr.ee/ragdasairafi369
              </span>
            </a> */}
            {/* <p className="font-medium leading-loose break-words">
              خبيرة في التغيير الشخصي وتحسين العلاقات الزوجية ، تساعد كل أنثى
              متزوجة تريد تغيير حياتها للأفضل وتجاوز الماضي وإعادة التوازن
              لجوانب حياتها
            </p>
            <p className="font-medium leading-loose break-words">
              يثق بها مئات الآلاف من النساء المثقفات الواعيات حول العالم اللاتي
              ساعدتهن بتغيير حياتهن من خلال برامجها العميقة وأيضا ساعدت حياة
              الكثيرات في تغيير حياتهن من خلال روايات تدريبية حقيقية لمشتركاتها
              السابقات بشكل مجاني عن التغيير
            </p>
            <p className="font-medium leading-loose break-words">
              وتحقيقاً لآية : (إن الله لايغير مابقوم حتى يغيروا مابأنفسهم ) تؤمن
              تماماً أنه لن يتغير العالم ويصلح إلا بالتغيير الشخصي من الداخل
              أولاً
            </p>
            <p className="font-medium leading-loose break-words">
              رُشحت عام ٢٠١٨ ضمن ٧٧ مرشح ومرشحة بمؤتمر قدوة الذي يهتم بإسقاط
              الضوء على الشخصيات الناجحة والمؤثرة والملهمة
            </p> */}
            {/* <p className="font-bold leading-loose break-words">
              رغده الصيرفي
            </p>
            <div className="font-bold leading-loose break-words flex flex-row justify-end gap-x-2">
              <p>رقم السجل التجاري </p>
              <p>
                7015912913
                <br />
                4650214023
              </p>
            </div> */}
          </div>
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default AboutRaghda;
