import React from "react";
import { Link } from "react-router-dom";

const Overview = () => {
  return (
    <div className="overview text-center text-white flex x-sm:h-80 lg:h-80">
      <div className="m-auto">
        <Link
          to="/free-courses"
          className="x-sm:space-y-2 md:space-y-8 2xl:space-y-12"
        >
          <p className="font-for-overview font-extrabold x-sm:text-2xl md:text-6xl 2xl:text-8xl">
            إن تغيرت فسيتغير العالم من حولك
          </p>
          <button className="bg-white text-pink-200 x-sm:py-1 px-4 md:py-2 md:px-8 x-sm:w-20 x-sm:mb-8 md:mb-0 md:w-36 2xl:w-52 outline-none focus:outline-none">
            <img src="/images/overview-button.png" alt="overview-button" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Overview;
