import Axios from "axios";

export async function subscribe(data) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/subscriptions/follow-up/subscribe`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function unlockModule(data) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/subscriptions/follow-up/unlock-module`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
