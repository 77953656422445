import React from "react";
import Navbar from "../Navbar";
import CourseTier from "./content/CourseTier";
import Contact from "../Contact";
import Footer from "../Footer";

const MyCoursePage = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar />
        <CourseTier title="قائمة دوراتي"></CourseTier>
        <Contact></Contact>
        <Footer />
      </div>
    </div>
  );
};

export default MyCoursePage;
