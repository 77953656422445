import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const VideoPlayer = ({ url, type, next }) => {
  const [playing, setPlaying] = useState(true);

  const playerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      document.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  return (
    <div className="w-full flex flex-col space-y-4">
      {type === "audio" && (
        <AudioPlayer
          autoPlay={true}
          autoPlayAfterSrcChange={true}
          src={url}
          // showJumpControls={false}
          // onPlay={(e) => console.log("onPlay")}
          // other props here
        />
      )}
      {type === "video" && (
        <div className="relative" style={{ paddingTop: "56.25%" }}>
          <ReactPlayer
            height="100%"
            width="100%"
            className="absolute top-0 left-0"
            ref={playerRef}
            // muted={true}
            playing={playing}
            controls={true}
            url={url}
            config={{
              file: {
                attributes: {
                  controlslist: "nodownload", //<- this is the important bit
                },
              },
            }}
            onEnded={() => {
              next();
              // alert("video ended right now 🎁");
            }}
          />
        </div>
      )}
      {type === "video" && (
        <div className="flex flex-row items-center justify-around space-x-6 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
          <button
            className="text-white bg-reghda-blue x-sm:p-1 md:p-2 2xl:p-4 rounded shadow-xl"
            onClick={() => {
              playerRef.current.seekTo(
                playerRef.current.getCurrentTime() - 10,
                "seconds"
              );
            }}
          >
            تراجع 10 ثواني
          </button>
          <button
            className="text-white bg-reghda-blue x-sm:p-1 md:p-2 2xl:p-4 rounded shadow-xl"
            onClick={() => {
              playerRef.current.seekTo(
                playerRef.current.getCurrentTime() + 10,
                "seconds"
              );
            }}
          >
            تقدم 10 ثواني
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
