import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { storeContext } from "./../global/store";
import { deleteTheUserAndTokenFromStorage } from "./../services/auth";
import { IconContext } from "react-icons";
import { AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { FaChalkboardTeacher, FaListAlt } from "react-icons/fa";
import { TiShoppingCart } from "react-icons/ti";
import { CgProfile } from "react-icons/cg";
import { GiBookshelf } from "react-icons/gi";
import { BiConversation } from "react-icons/bi";
import { FaSellsy } from "react-icons/fa";
import { GiSellCard } from "react-icons/gi";

import cn from "classnames";

const Navbar = () => {
  const { store, setStore } = useContext(storeContext);
  const [menuOpen, setMenuOpen] = useState(false);

  let menuStyle = cn(
    "relative cursor-pointer flex flex-col items-center justify-center md:ml-2 2xl:ml-4",
    { dropdown: menuOpen }
  );

  let menuItemsStyle = cn(
    "md:text-sm 2xl:text-2xl bg-reghda-blue flex flex-col items-center justify-center space-y-2 md:w-28 2xl:w-48 rounded-lg md:p-2 2xl:p-4 absolute hidden",
    { dropdownContent: menuOpen }
  );

  const changeVisibility = () => {
    const navToggle = document.getElementsByClassName("toggle");
    for (let i = 0; i < navToggle.length; i++) {
      navToggle.item(i).classList.toggle("hidden");
    }

    const navBarToggle = document.getElementsByClassName("nav__item");
    for (let i = 0; i < navBarToggle.length; i++) {
      navBarToggle.item(i).classList.toggle("hidden__nav");
    }
  };

  return (
    <div className=" text-center text-white bg-reghda-blue md:h-20 flex flex-col-reverse md:flex-row md:justify-around items-center md:text-xs lg:text-sm xl:text-base 2xl:text-xl 2xl:px-6">
      <div
        style={{ zIndex: "1000" }}
        className="bg-reghda-blue hidden__nav nav__item w-full p-2 flex flex-col-reverse md:flex-row md:justify-around xl:space-x-10  lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 items-center"
      >
        <div className="md:block md:float-left flex-45">
          <div className="flex flex-col-reverse  md:flex-row 2xl:space-x-9 lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 items-center">
            {/*             <a
              href="https://store.ragdasairafi369.com/"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-600 rounded-full p-2 flex flex-row x-sm:mt-4 md:mt-0">
              <span>
                <IconContext.Provider
                  value={{ size: "1.5em", style: { color: "#fff" } }}>
                  <TiShoppingCart></TiShoppingCart>
                </IconContext.Provider>
              </span>
              متجر رغد الحب
            </a> */}
            {!store.isLogged && (
              <Link to="/signup" className="hover:text-gray-600">
                انضم معنا
              </Link>
            )}
            {!store.isLogged && (
              <Link to="/login" className="hover:text-gray-600">
                تسجيل الدخول
              </Link>
            )}
            <Link to="/contact-us" className="hover:text-gray-600">
              تواصل معنا
            </Link>
            <Link to="/who-is-raghda" className="hover:text-gray-600">
              من نحن
            </Link>
            {store.isLogged && (
              <Link to="/cart">
                <IconContext.Provider
                  value={{ size: "2em", style: { color: "#fff" } }}
                >
                  <TiShoppingCart></TiShoppingCart>
                </IconContext.Provider>
              </Link>
            )}
            {store.isLogged && (
              <div className="hover:text-gray-600">
                <div className={menuStyle}>
                  <div
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <IconContext.Provider
                      value={{ size: "2em", style: { color: "#fff" } }}
                    >
                      <CgProfile></CgProfile>
                    </IconContext.Provider>
                    {/* <span>الملف الشخصي</span> */}
                  </div>
                  <div className={menuItemsStyle}>
                    <Link
                      to="/settings"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">اعدادات</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <AiFillSetting></AiFillSetting>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/my-courses"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">دوراتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <FaListAlt></FaListAlt>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/my-books"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">كتبي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <GiBookshelf></GiBookshelf>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/my-consultations"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">إستشاراتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <BiConversation></BiConversation>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/affiliate"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">تسويق</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <GiSellCard></GiSellCard>
                      </IconContext.Provider>
                    </Link>
                    {store.user && store.user.is_staff && (
                      <a
                        href="https://admin.ragdasairafi369.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                      >
                        <span className="">لوحة التحكم</span>
                        <IconContext.Provider
                          value={{ size: "1em", style: { color: "#fff" } }}
                        >
                          <FaChalkboardTeacher></FaChalkboardTeacher>
                        </IconContext.Provider>
                      </a>
                    )}
                    <Link
                      to="/my-orders"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">طلباتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <FaSellsy></FaSellsy>
                      </IconContext.Provider>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {store.isLogged && (
              <div
                onClick={() => {
                  setStore({
                    ...store,
                    isLogged: false,
                  });
                  deleteTheUserAndTokenFromStorage();
                }}
                // to="/"
                className="hover:text-gray-600 cursor-pointer"
              >
                <IconContext.Provider
                  value={{ size: "2em", style: { color: "#fff" } }}
                >
                  <FiLogOut></FiLogOut>
                </IconContext.Provider>
                {/* تسجيل الخروج */}
              </div>
            )}
          </div>
        </div>
        <div className="hidden md:block flex-10  mx-auto">
          <Link to="/">
            <img
              src="/images/Logo.png"
              alt="Logo"
              className=" w-20 h-auto block mx-auto"
            />
          </Link>
        </div>
        <div className="md:block md:float-right flex-45">
          <div className="flex flex-col-reverse justify-end md:flex-row 2xl:space-x-6 onehalfxl:space-x-6 md:space-x-4 lg:space-x-4  space-y-2 md:space-y-0  items-center">
            <Link to="/certificate" className="hover:text-gray-600">
              شهادات
            </Link>
            <Link to="/blogs" className="hover:text-gray-600">
              مقالات
            </Link>
            <Link to="/books" className="hover:text-gray-600">
              الكتب
            </Link>
            <Link to="/consulting" className="hover:text-gray-600">
              إستشارات
            </Link>
            <Link to="/payed-courses" className="hover:text-gray-600">
              دورات مدفوعة
            </Link>
            <Link to="/free-courses" className="hover:text-gray-600">
              دورات مجانية
            </Link>
            <Link to="/" className="hover:text-gray-600">
              الرئيسية
            </Link>
          </div>
        </div>
      </div>
      <div className="md:hidden self-end">
        <button
          id="hamburger"
          onClick={(e) => changeVisibility(e)}
          style={{ border: "none", outline: "none" }}
        >
          <img
            className="toggle block"
            src="https://img.icons8.com/f1f1f1/fluent-systems-regular/2x/menu-squared-2.png"
            alt="menu"
            width="40"
            height="40"
          />
          <img
            className="toggle hidden"
            src="https://img.icons8.com/f1f1f1/fluent-systems-regular/2x/close-window.png"
            alt="quite"
            width="40"
            height="40"
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
