import axios from "axios";

export async function joinProgram(token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `https://ragdasairafi369.smartiniaclient.com/affiliate/accounts/referrer/new`,
      {},
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function joinCampaign(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://ragdasairafi369.smartiniaclient.com/affiliate/subscriptions/join-campaign`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function unjoinCampaign(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://ragdasairafi369.smartiniaclient.com/affiliate/subscriptions/unjoin-campaign`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function addWithdrawOption(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/withdraw/users/options/create`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteWithdrawOption(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/withdraw/users/options/destroy/${id}`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function addWithdrawRequest(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `https://ragdasairafi369.smartiniaclient.com/payments/affiliate/withdraw/users/request/new`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function createLink(data, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `https://ragdasairafi369.smartiniaclient.com/affiliate/referrers/links/new`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteLink(id, token) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/affiliate/links/${id}/`,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
