import React, { useState, useEffect } from "react";
import BookCard from "./BookCard";

import axios from "axios";
import { getTheUserFromStorage } from "./../../../services/auth";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../services/fetcher";

const BookList = ({ title }) => {
  const initBooks = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/books/books/`,
    fetcher
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        "https://ragdasairafi369.smartiniaclient.com/students/mybooks",
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setData(res.data);
      return res;
    }
    fetch();
  }, []);

  return (
    <div className="h-full x-sm:p-4 md:px-20 space-y-8">
      <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
        <h1 className="text-reghda-blue">{title}</h1>
      </div>
      <div className="w-full h-full grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {initBooks &&
          initBooks.data &&
          initBooks.data.results.map((book) => {
            if (book.type === "free") {
              return <BookCard book={book}></BookCard>;
            }
          })}
        {data &&
          data.acquired_books.map((book) => <BookCard book={book}></BookCard>)}
      </div>
      {!initBooks.data && (!data || data.acquired_books.length === 0) && (
        <div className="text-center pt-6 pb-16 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
          <h1 className="text-gray-600">ليست لديك أي كتب في الوقت الحالي</h1>
        </div>
      )}
    </div>
  );
};

export default BookList;
