import React from "react";
import useSwr from "swr";
import LoaderModal from "../LoaderModal";
import { getTheUserFromStorage } from "../../services/auth";
import { fetcher } from "../../services/fetcher";

import {
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

const Orders = () => {
  const userId = getTheUserFromStorage().user_id;
  const { data, error } = useSwr(
    `https://ragdasairafi369.smartiniaclient.com/orders/my-orders/?user=${userId}`,
    fetcher
  );

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <div className="min-h-80 w-full xl:w-9/12 mx-auto mt-16 p-4 hidden md:block">
            <TableContainer component={Paper} className="rtl">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <p className="text-xl font-arabic font-semibold">
                        العناصر
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl font-arabic font-semibold">
                        التكلفة الإجمالية
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl font-arabic font-semibold">
                        طريقة الدفع
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl font-arabic font-semibold">
                        تاريخ الشراء
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl font-arabic font-semibold">
                        حالة الطلب
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((item) => (
                      <TableRow>
                        <TableCell align="center">
                          {item.items.length === 0 ? (
                            <p className="text-base font-arabic">
                              لا توجد عناصر بهذا الطلب
                            </p>
                          ) : (
                            <ul>
                              {item.items.map((product) => (
                                <li
                                  key={product.id}
                                  className="text-base font-arabic">
                                  {product.product_title}
                                </li>
                              ))}
                            </ul>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base font-arabic space-x-2">
                            <span>{item.total_cost}</span> <span>ريال</span>
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base font-arabic">
                            {item.payment_method}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base font-arabic">
                            {item.created_at.slice(0, 10)}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            className={`text-base font-arabic ${
                              item.status === "مرفوض" && "text-red-500"
                            } ${item.status === "مقبول" && "text-green-500"}`}>
                            {item.status}
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="min-h-80 w-full xl:w-8/12 mx-auto mt-16 p-4 block md:hidden">
            {data &&
              data.map((item) => (
                <TableContainer
                  key={item.id}
                  component={Paper}
                  className="rtl mb-4">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base font-arabic font-semibold">
                            العناصر
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {item.items.length === 0 ? (
                            <p className="text-base font-arabic">
                              لا توجد عناصر بهذا الطلب
                            </p>
                          ) : (
                            <ul>
                              {item.items.map((product) => (
                                <li
                                  key={product.id}
                                  className="text-xl font-arabic">
                                  {product.product_title}
                                </li>
                              ))}
                            </ul>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base font-arabic font-semibold">
                            التكلفة الإجمالية
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base font-arabic space-x-2">
                            <span>{item.total_cost}</span> <span>ريال</span>
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base font-arabic font-semibold">
                            طريقة الدفع
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base font-arabic">
                            {item.payment_method}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base font-arabic font-semibold">
                            تاريخ الشراء
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base font-arabic">
                            {item.created_at.slice(0, 10)}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base font-arabic font-semibold">
                            حالة الطلب
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            className={`text-xl font-arabic ${
                              item.status === "مرفوض" && "text-red-500"
                            } ${item.status === "مقبول" && "text-green-500"}`}>
                            {item.status}
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
          </div>
        </>
      ) : (
        <div className="min-h-80 w-full flex justify-center items-center  text-6xl font-extrabold text-reghda-black">
          الطلبات فارغة
        </div>
      )}
      <LoaderModal modalIsOpen={!data}></LoaderModal>
    </>
  );
};

export default Orders;
