import React from "react";
import Navbar from "./../Navbar";
import Overview from "./content/Overview";
import AboutOne from "./content/about/AboutOne";
import AboutTwo from "./content/about/AboutTwo";
import AboutThree from "./content/about/AboutThree";
import AboutFour from "./content/about/AboutFour";
import AboutFive from "./content/about/AboutFive";
import Contact from "./../Contact";
import Footer from "./../Footer";

function Page() {
  return (
    <div className="my-0 mx-auto h-auto">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <Overview></Overview>
        <AboutOne></AboutOne>
        <AboutTwo></AboutTwo>
        <AboutThree></AboutThree>
        <AboutFour></AboutFour>
        <AboutFive></AboutFive>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Page;
