import React from "react";
import Navbar from "./../Navbar";
import BlogList from "./content/BlogList";
import Contact from "./../Contact";
import Footer from "./../Footer";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";

const BlogsPage = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <BlogList></BlogList>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default BlogsPage;
