import React from "react";
import { Link } from "react-router-dom";

const AboutOne = () => {
  return (
    <div className="text-center h-80 flex flex-col x-sm:items-center md:flex-row md:px-20 py-16">
      <div className="flex-1">
        <img
          className="x-sm:w-36 x-sm:h-36 md:w-64 md:h-64 lg:w-about-pics lg:h-about-pics 2xl:w-about-pics-xl 2xl:h-about-pics-xl"
          src="images/newReghda-removebg-preview.png"
          alt="Layer"
        />
      </div>
      <div className="flex-1 text-right flex flex-col text-gray-600">
        <div className="mb-10">
          <h1 className="x-sm:text-lg sm:text-2xl lg:text-6xl 2xl:text-8xl text-right font-bold">
            أهلاً و س<span className="bg-pink-200">هلاً</span>
          </h1>
        </div>
        <div className="x-sm:mr-2 sm:mr-16 x-sm:text-xs md:text-base lg:text-xl 2xl:text-3xl tracking-wide">
          <p>
            في <span className="bg-indigo-200">عالم</span>ي المنير الذي أنار
            بفضل الله حياة الألاف
            <br /> وسينير حياتك بإذن الله
          </p>
          <p>
            اسمح لي أن أعبر لك عن كامل إمتناني لك لإيمانك
            <br /> بذاتك وأخذك هذه الخطوة تجاه نفسك بتنويرها
            <br /> و مساعدته
          </p>
        </div>
        <Link
          to="/who-is-raghda"
          className="text-center bg-gradient-to-r from-pink-400 to-indigo-300 text-white mt-6 py-2 px-4 rounded x-sm:text-xs x-sm:w-24 2xl:w-48 2xl:text-3xl">
          لقراءة المزيد
        </Link>
      </div>
    </div>
  );
};

export default AboutOne;
