import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoaderModal from "../../LoaderModal";
import PayButton from "./PayButton";
import MadaButton from "./MadaButton";
import BankTransferButton from "./BankTransferButton";
import ReactPixel from "react-facebook-pixel";

const CheckOut = () => {
  const history = useHistory();
  const total = history.location.state.data.total;
  const cartId = history.location.state.data.cartId;
  const books = history.location.state.data.books;
  const courses = history.location.state.data.courses;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactPixel.track("InitiateCheckout", {
      content_category: "courses-books",
      content_ids: [
        ...courses.map((course) => course.id),
        ...books.map((book) => book.id),
      ],
      currency: "SAR",
      value: Math.round(total),
    });
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          <p className="border-b-2 border-reghda-blue pb-4 text-reghda-blue text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
            المشتريات
          </p>
          {courses &&
            courses.length > 0 &&
            courses.map((course, i) => (
              <div
                key={i}
                className="flex flex-row items-center justify-between py-4"
              >
                <p className="flex flex-row items-center justify-center space-x-2 text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  <span>ريال</span>
                  <span>{`${course.price}`}</span>
                </p>
                <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  {`${course.title}`}
                </p>
              </div>
            ))}
          {books &&
            books.length > 0 &&
            books.map((book, i) => (
              <div
                key={i}
                className="flex flex-row items-center justify-between py-4"
              >
                <p className="flex flex-row items-center justify-center space-x-2 text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  <span>ريال</span>
                  <span>{`${book.price}`}</span>
                </p>
                <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  {`${book.name}`}
                </p>
              </div>
            ))}
          {/* <div className="flex flex-row items-center justify-between py-4">
            <p className="flex flex-row items-center justify-center space-x-2 text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              <span>+15</span>
            </p>
            <p className="text-reghda-pink text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              ضريبة القيمة المضافة
            </p>
          </div> */}
          <div className="flex flex-row items-center justify-between py-4">
            <p className="flex flex-row items-center justify-center space-x-2 text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              <span>ريال</span>
              <span>{`${Math.floor(total)}`}</span>
              {/* <span>{`${Math.floor(total * 1.15)}`}</span> */}
            </p>
            <p className="text-reghda-pink text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              المجموع
            </p>
          </div>
        </div>
      </div>
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          {/* <MadaButton setLoading={setLoading} cartId={cartId}></MadaButton>
          <br />
          <br /> */}
          <PayButton
            setLoading={setLoading}
            // total={Math.floor(total * 1.15)}
            total={Math.floor(total)}
            books={books}
            courses={courses}
          ></PayButton>
          <br />
          <br />
          <BankTransferButton
            // total={Math.floor(total * 1.15)}
            total={Math.floor(total)}
            books={books}
            courses={courses}
            setLoading={setLoading}
          ></BankTransferButton>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CheckOut;
