import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { storeContext } from "./../global/store";
import { Link, Redirect, useHistory } from "react-router-dom";
import { signup } from "./../services/auth";

import jwt from "jwt-decode";
import { login, storeTheUser, loginWithGoogle } from "./../services/auth";
import { GoogleLogin } from "react-google-login";

import { IconContext } from "react-icons";
import { FcCdLogo, FcGoogle } from "react-icons/fc";

import { toast } from "react-toastify";

const Signup = () => {
  let history = useHistory();
  const { store, setStore } = useContext(storeContext);

  const validationSchema = Yup.object({
    username: Yup.string().required("هذا الحقل مطلوب"),
    given_name: Yup.string().required("هذا الحقل مطلوب"),
    family_name: Yup.string().required("هذا الحقل مطلوب"),
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
    confirmPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .oneOf([Yup.ref("password"), null], "يجب ان تتطابق كلمات المرور"),
  });

  if (store.isLogged) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-indigo-200 h-screen w-1/2 overview-sl"></div>
      <div className="h-screen overflow-y-auto x-sm:w-full md:w-1/2 flex flex-col x-sm:space-y-2 md:space-y-3 2xl:space-y-6 text-right text-reghda-black x-sm:px-8 md:px-12 2xl:px-24 x-sm:text-sm md:text-base 2xl:text-3xl">
        <Link to="/">
          <div className="p-4 flex flex-col items-center">
            <img
              className="flex-1 x-sm:w-20 x-sm:h-20 md:w-20 md:h-20 2xl:w-48 2xl:h-48"
              alt="logo"
              src="/images/bottom_logo.png"
            />
          </div>
        </Link>
        <p className="md:text-xl 2xl:text-4xl font-extrabold">
          انشاء حساب جديد
        </p>
        <p>مرحبا بكم , يرجى ملئ جميع الحقول التالية </p>
        <Formik
          initialValues={{
            username: "",
            given_name: "",
            family_name: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const { username, given_name, family_name, password, email } =
              values;
            const res = await signup({
              email,
              password,
              username,
              given_name,
              family_name,
              auth_provider: "email",
            });
            if (res && res.data && res.status === 200) {
              toast(`${res.data.message} ✔`);
              history.push("/login");
            } else {
              toast(res.data.message);
            }
            setSubmitting(false);
          }}
        >
          <Form className="text-white x-sm:space-y-2 md:space-y-2 2xl:space-y-4">
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="إسم المستخدم"
              name="username"
              type="text"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="username" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="الإسم"
              name="given_name"
              type="text"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="given_name" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="اللقب"
              name="family_name"
              type="text"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="family_name" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="البريد الالكتروني"
              name="email"
              type="email"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="email" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="كلمة السر"
              name="password"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="password" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="تاكيد كلمة السر"
              name="confirmPassword"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="confirmPassword" />
            </div>
            <button
              className="bg-reghda-black text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
              type="submit"
            >
              سجل معنا
            </button>
            <GoogleLogin
              clientId="779569317705-gpjcu6dvptj6r66e25e4udcro16umd03.apps.googleusercontent.com"
              render={(renderProps) => (
                <div
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                  className="cursor-pointer flex flex-row items-center justify-center space-x-2 bg-white text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-2xl"
                >
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#fff" } }}
                  >
                    <FcGoogle></FcGoogle>
                  </IconContext.Provider>
                  <p>تسجيل الدخول بالجيميل</p>
                </div>
              )}
              buttonText="تسجيل الدخول بالجيميل"
              onSuccess={async (res) => {
                let token = res.tokenId;
                let user = { ...jwt(token) };

                let values = {
                  username: user.name,
                  email: user.email,
                  auth_provider: "google.com",
                  family_name: user.family_name,
                  given_name: user.given_name,
                  image: user.picture,
                };

                const res2 = await loginWithGoogle(values);

                if (res2 && res2.status === 200 && res2.data) {
                  const token2 = res2.data.access;
                  const refresh2 = res2.data.refresh;
                  const user2 = jwt(res2.data.access);
                  setStore({
                    ...store,
                    token2,
                    user2,
                    isLogged: true,
                  });
                  storeTheUser(user2, token2, refresh2);
                  history.goBack();
                } else {
                  toast("هنالك خطاءً ما ❌");
                }
              }}
              // onFailure={(res) => {
              //   alert("something wrong!, please try again");
              // }}
              cookiePolicy={"single_host_origin"}
            />
          </Form>
        </Formik>
        <p>
          بتسجيلك على الموقع أنت توافق على
          <span className="text-pink-200"> شروط الخدمة و سياسة الخصوصية</span>
        </p>
        <Link to="/login">
          لديك حساب ؟ <span className="text-pink-200">سجل الدخول</span>
        </Link>
      </div>
    </div>
  );
};

export default Signup;
