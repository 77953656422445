import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import Affiliate from "./content/Affiliate";

const AffiliateContainer = () => {
  return (
    <>
      <Navbar></Navbar>
      <Affiliate />
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default AffiliateContainer;
