import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const CourseCard = ({ courseDetail }) => {
  const history = useHistory();

  return (
    <div className="course__item">
      <div className="course__img">
        <img src={courseDetail.picture} alt="course"></img>
      </div>
      <div className="course__content">
        <p className="course__title">{courseDetail.title}</p>
      </div>
      <div className="course__subscribe">
        <button
          onClick={() => {
            if (courseDetail.type === "follow-up") {
              history.push(`/follow-course/${courseDetail.slug}`);
            } else {
              history.push(`/course/${courseDetail.slug}`, {
                data: {
                  chapterId: null,
                  moduleId: null,
                },
              });
            }
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <p>اذهب إلى دورتي</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default CourseCard;
