import Axios from "axios";

export async function addComment(data) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/reviews/books/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteComment(bookId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/reviews/books/${bookId}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editComment(data) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/reviews/books/${data.bookId}/`,
      { comment: data.detail, rating: data.rating }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addResponseReview({ user, parent, body }) {
  try {
    const response = await Axios.post(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/books/`,
      {
        user,
        parent,
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editResponseReview({ responseId, body }) {
  try {
    const response = await Axios.patch(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/books/${responseId}/`,
      {
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function deleteResponseReview(responseId) {
  try {
    const response = await Axios.delete(
      `https://ragdasairafi369.smartiniaclient.com/reviews/answers/books/${responseId}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
