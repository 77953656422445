import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineArrowDown, AiOutlineArrowRight } from "react-icons/ai";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import VideoPlayer from "./VideoPlayer";
import ChapterItems from "./ChapterItems";
import Collapsible from "react-collapsible";
import OpenNextModel from "./OpenNextModel";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import Reviews from "../course-study/Reviews";

const FollowCourseStudyPage = () => {
  const { slug } = useParams();
  let location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/follow-up/details/${slug}`,
    fetcher
  );

  const [totalState, SetTotalState] = useState({
    chapters: [],
    // numberOfChapters: 3,
  });

  const [currentUrl, setCurrentUrl] = useState("");
  const [currentAttachment, setCurrentAttachment] = useState({
    files: [],
    images: [],
    audios: [],
  });
  const [currentType, setCurrentType] = useState("video");

  const [currentChapter, setCurrentChapter] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);

  const [canUnlock, setCanUnlock] = useState(false);

  useEffect(() => {
    data &&
      SetTotalState({
        chapters: data.chapters.map((chapter, i) => {
          return { ...chapter, numberOfModules: chapter.modules.length };
        }),
        numberOfChapters: data.chapters.length,
      });
  }, [data]);

  const setupCurrent = (chapterId, moduleId) => {
    if (chapterId && moduleId) {
      let thisChapter = totalState.chapters.filter(
        (chapter) => chapter.id === chapterId
      )[0];
      let thisModule = thisChapter.modules.filter(
        (module) => module.id === moduleId
      )[0];

      if (thisModule.videos.length > 0) {
        thisModule.videos[0].url === ""
          ? setCurrentUrl(thisModule.videos[0].video_content)
          : setCurrentUrl(thisModule.videos[0].url);
      }

      setCurrentAttachment({
        files: [...thisModule.files],
        images: [...thisModule.images],
        audios: [...thisModule.audios],
      });
    }
  };

  // const next = () => {
  //   let nextModuleIdx;
  //   let nextChapterIdx;

  //   let thisChapter = totalState.chapters.filter((chapter, i) => {
  //     if (chapter.id === currentChapter) {
  //       nextChapterIdx = i + 1;
  //       return chapter;
  //     }
  //   })[0];

  //   let thisModule = thisChapter.modules.filter((module, i) => {
  //     if (module.id === currentModule) {
  //       nextModuleIdx = i + 1;
  //       return module;
  //     }
  //   })[0];

  //   if (nextModuleIdx < thisChapter.numberOfModules) {
  //     let nextModule = thisChapter.modules.filter(
  //       (module, i) => i === nextModuleIdx
  //     )[0];
  //     setupCurrent(thisChapter.id, nextModule.id);
  //     setCurrentChapter(thisChapter.id);
  //     setCurrentModule(nextModule.id);
  //   } else {
  //     if (nextChapterIdx < totalState.numberOfChapters) {
  //       let nextChapter = totalState.chapters.filter(
  //         (chapter, i) => i === nextChapterIdx
  //       )[0];
  //       let firstModule = nextChapter.modules.filter((module, i) => i === 0)[0];
  //       setupCurrent(nextChapter.id, firstModule.id);
  //       setCurrentChapter(nextChapter.id);
  //       setCurrentModule(firstModule.id);
  //     }
  //   }
  // };

  // const previous = () => {
  //   let prevModuleIdx;
  //   let prevChapterIdx;

  //   let thisChapter = totalState.chapters.filter((chapter, i) => {
  //     if (chapter.id === currentChapter) {
  //       prevChapterIdx = i - 1;
  //       return chapter;
  //     }
  //   })[0];

  //   let thisModule = thisChapter.modules.filter((module, i) => {
  //     if (module.id === currentModule) {
  //       prevModuleIdx = i - 1;
  //       return module;
  //     }
  //   })[0];

  //   if (prevModuleIdx >= 0) {
  //     let prevModule = thisChapter.modules.filter(
  //       (module, i) => i === prevModuleIdx
  //     )[0];
  //     setupCurrent(thisChapter.id, prevModule.id);
  //     setCurrentChapter(thisChapter.id);
  //     setCurrentModule(prevModule.id);
  //   } else {
  //     if (prevChapterIdx >= 0) {
  //       let prevChapter = totalState.chapters.filter(
  //         (chapter, i) => i === prevChapterIdx
  //       )[0];
  //       let lastModule = prevChapter.modules.filter(
  //         (module, i) => i === prevChapter.numberOfModules - 1
  //       )[0];
  //       setupCurrent(prevChapter.id, lastModule.id);
  //       setCurrentChapter(prevChapter.id);
  //       setCurrentModule(lastModule.id);
  //     }
  //   }
  // };

  // const showNext = () => {
  //   let currentChapterIdx;
  //   let currentModuleIdx;

  //   let thisChapter = totalState.chapters.filter((chapter, i) => {
  //     if (chapter.id === currentChapter) {
  //       currentChapterIdx = i;
  //       return chapter;
  //     }
  //   })[0];
  //   let thisModule = thisChapter.modules.filter((module, i) => {
  //     if (module.id === currentModule) {
  //       currentModuleIdx = i;
  //       return module;
  //     }
  //   })[0];

  //   if (
  //     currentChapterIdx === totalState.numberOfChapters - 1 &&
  //     currentModuleIdx === thisChapter.numberOfModules - 1
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  // const showPrev = () => {
  //   let currentChapterIdx;
  //   let currentModuleIdx;

  //   let thisChapter = totalState.chapters.filter((chapter, i) => {
  //     if (chapter.id === currentChapter) {
  //       currentChapterIdx = i;
  //       return chapter;
  //     }
  //   })[0];
  //   let thisModule = thisChapter.modules.filter((module, i) => {
  //     if (module.id === currentModule) {
  //       currentModuleIdx = i;
  //       return module;
  //     }
  //   })[0];

  //   if (currentChapterIdx === 0 && currentModuleIdx === 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <div>
      <Navbar></Navbar>
      <div className="min-h-80 h-full 2xl:min-h-screen p-4 md:p-16 flex flex-col x-sm:space-y-6 md:flex-row items-center justify-around space-x-12">
        {!currentModule && (
          <div className="w-2/3 flex flex-row space-x-4 text-center items-center justify-center font-extrabold md:text-lg lg:text-2xl 2xl:text-4xl">
            <p>إبدا الدورة الأن</p>
            <IconContext.Provider
              value={{ size: "1em", style: { color: "#000" } }}>
              <AiOutlineArrowRight></AiOutlineArrowRight>
            </IconContext.Provider>
          </div>
        )}
        {currentModule && (
          <div className="flex flex-col w-full md:w-2/3 space-y-4 md:text-lg lg:text-2xl 2xl:text-4xl">
            <VideoPlayer
              // next={next}
              type={currentType}
              url={currentUrl}></VideoPlayer>
            <Collapsible
              trigger={
                <div className="w-full flex flex-row items-center justify-center space-x-2 bg-reghda-blue text-white text-center rounded-lg x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                  <p>مرفقات</p>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#fff" } }}>
                    <AiOutlineArrowDown></AiOutlineArrowDown>
                  </IconContext.Provider>
                </div>
              }
              className="text-right p-2"
              openedClassName="text-right p-2">
              <div className="flex flex-col">
                {currentAttachment.files.length > 0 &&
                  currentAttachment.files.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.file_content)
                      : (where = item.url);
                    return (
                      <a
                        href={where}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl underline cursor-pointer">
                        {item.title}
                      </a>
                    );
                  })}
                {currentAttachment.images.length > 0 &&
                  currentAttachment.images.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.image_content)
                      : (where = item.url);
                    return (
                      <a
                        href={where}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl underline cursor-pointer">
                        {item.title}
                      </a>
                    );
                  })}
                {currentAttachment.audios.length > 0 &&
                  currentAttachment.audios.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.audio_content)
                      : (where = item.url);
                    return (
                      <a
                        href={where}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl underline cursor-pointer">
                        {item.title}
                      </a>
                    );
                  })}
              </div>
            </Collapsible>
            {/* <div className="">
              {showPrev() && (
                <button
                  className="text-white bg-reghda-blue x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-left"
                  onClick={previous}
                >
                  السابق
                </button>
              )}
              {showNext() && (
                <button
                  className="text-white bg-reghda-blue x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-right"
                  onClick={next}
                >
                  التالي
                </button>
              )}
            </div> */}
            {canUnlock && (
              <div className="">
                <button
                  className="text-white bg-reghda-blue x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-right"
                  onClick={() => setIsOpen(true)}>
                  إفتح التالي
                </button>
              </div>
            )}
            <Reviews
              setLoading={setLoading}
              courseId={data.id}
              moduleId={currentModule}
            />
          </div>
        )}
        {data && (
          <ChapterItems
            setCanUnlock={setCanUnlock}
            courseId={data.id}
            setCurrentChapter={setCurrentChapter}
            currentChapter={currentChapter}
            setCurrentModule={setCurrentModule}
            currentModule={currentModule}
            setupCurrent={setupCurrent}
            chapters={totalState.chapters}></ChapterItems>
        )}
      </div>
      <Contact></Contact>
      <Footer></Footer>
      {data && (
        <OpenNextModel
          courseId={data.id}
          modalIsOpen={modalIsOpen}
          closing={() => setIsOpen(false)}></OpenNextModel>
      )}
    </div>
  );
};

export default FollowCourseStudyPage;

// https://www.w3schools.com/html/mov_bbb.mp4
// https://www.youtube.com/watch?v=ysz5S6PUM-U
