import React, { useEffect, useState, useContext } from "react";
import { storeContext } from "./../../../global/store";
import axios from "axios";
import BookCard from "./BookCard";
import { getTheUserFromStorage } from "./../../../services/auth";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../../services/fetcher";

const BookList = ({ title }) => {
  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/books/books/`,
    fetcher
  );
  const { store, setStore } = useContext(storeContext);
  const [myBooks, setMyBooks] = useState(null);

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        "https://ragdasairafi369.smartiniaclient.com/students/mybooks",
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setMyBooks(res.data);
      return res;
    }
    store.isLogged && fetch();
  }, []);

  return (
    <div className="min-h-80 h-full x-sm:p-4 md:px-20 space-y-8">
      <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
        <h1 className="text-reghda-blue">{title}</h1>
      </div>
      <div className="w-full h-full grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {store.isLogged &&
          data &&
          myBooks &&
          data.results.map((book) => {
            if (myBooks.acquired_books.find((item) => item.id === book.id)) {
              return <BookCard book={book} myBook={true}></BookCard>;
            } else {
              return <BookCard book={book} myBook={false}></BookCard>;
            }
          })}
        {data &&
          !store.isLogged &&
          data.results.map((book) => (
            <BookCard book={book} myBook={false}></BookCard>
          ))}
      </div>
    </div>
  );
};

export default BookList;
