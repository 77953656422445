import React, { useEffect } from "react";
import PayedCourseCard from "./PayedCourseCard";
import FollowCourseCard from "./FollowCourseCard";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";

const CoursesList = ({ title }) => {
  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/paid/`,
    fetcher
  );
  const followCourses = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/follow-up/`,
    fetcher
  );

  return (
    <div className="min-h-80">
      <div className="h-full">
        <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
          <h1 className="text-pink-300">{title}</h1>
        </div>
        <div className="card-container p-8 lg:p-16 text-gray-700">
          {data &&
            data.map((course, i) => (
              <PayedCourseCard
                course={course}
                slug={course.slug}
                id={course.id}
                url={course.picture}
                name={course.title}
                description={course.description}
                price={course.price}></PayedCourseCard>
            ))}
        </div>
      </div>
      {followCourses.data && followCourses.data.length > 0 && (
        <div className="h-full">
          <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
            <h1 className="text-pink-300">قائمة دورات المتابعة</h1>
          </div>
          <div className="card-container p-8 lg:p-16 text-gray-700">
            {followCourses.data.map((course, i) => (
              <FollowCourseCard
                course={course}
                slug={course.slug}
                id={course.id}
                url={course.picture}
                name={course.title}
                description={course.description}
                price={course.price}></FollowCourseCard>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursesList;
