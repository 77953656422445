import React, { useState } from "react";
import useSWR from "swr";
import { getTheTokenFromStorage } from "../../../services/auth";
import { fetcherAuth } from "../../../services/fetcher";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { joinCampaign, unjoinCampaign } from "../../../services/affiliate";
import LoaderModal from "../../LoaderModal";
import { toast } from "react-toastify";
import CreateLinkModal from "./CreateLinkModal";

const Account = () => {
  const token = getTheTokenFromStorage();
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [currentCampaign, setCurrentCampaign] = useState(null);

  const { data, error, mutate } = useSWR(
    [
      `https://ragdasairafi369.smartiniaclient.com/affiliate/subscriptions/joined-campaigns`,
      token,
    ],
    fetcherAuth
  );

  const handleJoinProgram = async (id) => {
    setLoading(true);
    const res = await joinCampaign({ campaign: id }, token);
    mutate();
    setLoading(false);
    toast(`${res?.data?.message}`);
  };

  const handleUnjoinProgram = async (id) => {
    setLoading(true);
    const res = await unjoinCampaign({ campaign: id }, token);
    mutate();
    setLoading(false);
    toast(`${res?.data?.message}`);
  };

  return (
    <div className="w-full h-full flex justify-center items-center px-4 py-6 rtl">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">الدورة</TableCell>
              <TableCell align="center">اسم الحملة</TableCell>
              <TableCell align="center">وصف الحملة</TableCell>
              <TableCell align="center">نوع المكافأة</TableCell>
              <TableCell align="center">مبلغ المكافأة</TableCell>
              <TableCell align="center"> إشتراك</TableCell>
              <TableCell align="center">رابط</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((campaign) => (
              <TableRow key={campaign.name}>
                <TableCell align="center" component="th" scope="row">
                  {campaign?.course.title}
                </TableCell>
                <TableCell align="center">{campaign?.name}</TableCell>
                <TableCell align="center">{campaign?.description}</TableCell>
                <TableCell align="center">
                  {campaign.reward_amount ? "مبلغ" : "النسبة المئوية"}
                </TableCell>
                <TableCell align="center">
                  {campaign.reward_amount
                    ? campaign.reward_amount
                    : `${campaign?.reward_percentage * 100}%`}
                </TableCell>
                <TableCell align="center">
                  {campaign.is_subscribed ? (
                    <button
                      className="px-4 py-2 bg-red-400 rounded-md text-white"
                      onClick={() => handleUnjoinProgram(campaign.id)}>
                      إلغاء
                    </button>
                  ) : (
                    <button
                      className="px-4 py-2 bg-reghda-blue rounded-md text-white"
                      onClick={() => handleJoinProgram(campaign.id)}>
                      اشترك
                    </button>
                  )}
                </TableCell>
                {campaign.is_subscribed ? (
                  <TableCell align="center" component="th" scope="row">
                    <p
                      className="text-reghda-blue underline font-extrabold cursor-pointer"
                      onClick={() => {
                        setCurrentCampaign(campaign.id);
                        setOpenCreateModal(true);
                      }}>
                      أنشئ
                    </p>
                  </TableCell>
                ) : (
                  <TableCell align="center" component="th" scope="row">
                    <p>/</p>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
      <CreateLinkModal
        isOpen={openCreateModal}
        closing={() => {
          setOpenCreateModal(false);
        }}
        id={currentCampaign}></CreateLinkModal>
    </div>
  );
};

export default Account;
