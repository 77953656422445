import React, { useState } from "react";
import { pay } from "./../../../services/pay";

const MadaButton = ({ setLoading, cartId }) => {
  return (
    <>
      <p className="pb-4 text-reghda-blue text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
        الدفع
      </p>
      <div className="flex flex-row items-center justify-between py-4">
        <div className="flex flex-row space-x-4 items-center justify-center">
          <img
            src="/images/footer/visa.png"
            className="x-sm:h-6 sm:h-10"
            alt="icon"
          />
          <img
            src="/images/footer/mastercard.png"
            className="x-sm:h-3 sm:h-5"
            alt="icon"
          />
          <img
            src="/images/footer/mada.png"
            className="x-sm:h-3 sm:h-5"
            alt="icon"
          />
        </div>
        <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
          الدفع عبر
        </p>
      </div>
      <button
        onClick={async () => {
          setLoading(true);
          let res = await pay({
            cart_id: cartId,
          });
          localStorage.setItem("currentPaymentRef", res.data.response.tran_ref);
          setLoading(false);
          window.location.replace(res.data.response.redirect_url);
        }}
        className="w-full flex flex-row  items-center justify-center py-2 space-x-4 bg-gradient-to-r from-pink-400 to-indigo-300 text-white rounded-xl  x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16  x-sm:text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none"
      >
        <div>إدفع</div>
      </button>
    </>
  );
};

export default MadaButton;
