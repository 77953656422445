import { PayPalButton } from "react-paypal-button-v2";
import { enroll } from "./../../../services/enrollment";
import { useHistory } from "react-router-dom";
import { getTheUserFromStorage } from "./../../../services/auth";
import { clearCart } from "../../../services/cart";
import { createOrder } from "../../../services/orders";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";

const PayButton = ({ total, books, courses, setLoading }) => {
  const history = useHistory();
  return (
    <>
      <div className="flex flex-row items-center justify-between py-4">
        <div className="flex flex-row space-x-4 items-center justify-center">
          <img
            src="/images/footer/paypal.png"
            className="x-sm:h-3 sm:h-5"
            alt="icon"
          />
        </div>
        <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
          الدفع عبر
        </p>
      </div>
      <PayPalButton
        style={{
          color: "blue",
          shape: "rect",
          size: "responsive",
          // label: "checkout",
        }}
        options={{
          // clientId: "sb",
          clientId:
            "Ae06eB3XMR5ZLW6pShc1dH6F9cVDVAd4FaPiQTYkA89WPB1H8Z1vwjL84VpacbfF13o6ThcMOGkdveWg",
          currency: "USD",
          disableFunding: "card",
          // disableFunding: "card",
          // disableFunding: "credit,card",
        }}
        // amount={total / 3.75}
        amount={Math.round(total / 3.75)}
        shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={async (details, data) => {
          setLoading(true);
          //Call your server to save the transaction
          if (details.status === "COMPLETED") {
            // create order
            await createOrder({
              user: getTheUserFromStorage().user_id,
              payment_method: "Paypal",
            });
            // enroll
            let res = await enroll({
              course: courses.map((course) => course.id),
              book: books.map((book) => book.id),
              user: getTheUserFromStorage().user_id,
            });
            if (res && res.status === 201) {
              setLoading(false);
              toast("العملية تمت بنجاح 🎉");
              ReactPixel.track("Purchase", {
                content_ids: [
                  ...courses.map((course) => course.id),
                  ...books.map((book) => book.id),
                ],
                currency: "USD",
                value: Math.round(total / 3.75),
              });
              await clearCart(getTheUserFromStorage().user_id);
              history.push("/my-orders");
            } else {
              setLoading(false);
              toast("العملية لم تتم بنجاح ❌");
            }
          }
          setLoading(false);
        }}
        onError={(err) => {
          toast("العملية لم تتم بنجاح ❌");
          console.log("👀", err);
        }}
        catchError={(err) => {
          toast("العملية لم تتم بنجاح ❌");
          console.log("❌", err);
        }}
      />
    </>
  );
};

export default PayButton;

// c id Ae06eB3XMR5ZLW6pShc1dH6F9cVDVAd4FaPiQTYkA89WPB1H8Z1vwjL84VpacbfF13o6ThcMOGkdveWg
// secret EB15dNwKOn0wt593HzvBf77tHIOOTsN05m4ddDgskqHBYYpsRj4543kY5DaKb2T9ieRJEi4nICn7RItc

//sandbox
// sb-lg7yq5468518@personal.example.com
// GN.j7)o.
