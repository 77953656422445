import Axios from "axios";

export async function login(data) {
  // try {
  //   const response = await Axios.post(`https://ragdasairafi369.smartiniaclient.com/users/token`, data);
  //   return response;
  // } catch (error) {
  //   let errorObject = JSON.stringify(error.response);
  //   console.log(errorObject, "🎉");
  //   return errorObject;
  // }
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);
  const res = await fetch(
    `https://ragdasairafi369.smartiniaclient.com/users/token`,
    {
      method: "POST",
      body: formData,
    }
  );
  const jsonData = await res.json();
  const status = await res.status;
  return { status, data: jsonData };
}

export async function loginWithGoogle(data) {
  const response = await Axios.post(
    `https://ragdasairafi369.smartiniaclient.com/users/social-auth`,
    data
  );
  return response;
}

export async function signup(data) {
  // const response = await Axios.post(`https://ragdasairafi369.smartiniaclient.com/users/register`, data);
  // return response;
  const formData = new FormData();
  formData.append("username", data.username);
  formData.append("given_name", data.given_name);
  formData.append("family_name", data.family_name);
  formData.append("email", data.email);
  formData.append("password", data.password);
  formData.append("auth_provider", data.auth_provider);

  const res = await fetch(
    `https://ragdasairafi369.smartiniaclient.com/users/register`,
    {
      method: "POST",
      body: formData,
    }
  );
  const jsonData = await res.json();
  const status = await res.status;
  return { status, data: jsonData };
}

export function storeTheUser(data, token, refresh) {
  localStorage.setItem("token", token);
  localStorage.setItem("refresh", refresh);
  localStorage.setItem("user", JSON.stringify(data));
}

export function getTheUserFromStorage() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getTheTokenFromStorage() {
  return localStorage.getItem("token");
}

export function getTheRefresh() {
  return localStorage.getItem("refresh");
}

export function deleteTheUserAndTokenFromStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.removeItem("user");
}

export function storeTheCoupon(coupon) {
  localStorage.setItem("coupon", coupon);
}

export function getTheCoupon() {
  return localStorage.getItem("coupon");
}

export function removeTheCoupon() {
  localStorage.removeItem("coupon");
}
