import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { AiTwotoneBank } from "react-icons/ai";
import { addWithdrawRequest } from "../../../services/affiliate";
import { getTheTokenFromStorage } from "../../../services/auth";

export default function SimpleModal({
  open,
  handleClose,
  withdrawOptions,
  balanceData,
  mutate,
}) {
  const token = getTheTokenFromStorage();

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [amount, setAmount] = useState("");
  const [success, setSuccess] = useState(false);

  const handleAddRequest = async () => {
    const dataSent = {
      withdraw_option: selectedMethod,
      amount,
    };

    if (amount <= balanceData?.available_amount) {
      const res = await addWithdrawRequest(dataSent, token);
      if (res?.status === 200) {
        setSuccess(true);
        mutate();
      } else {
        alert(res?.data?.message);
      }
    } else {
      alert("ليس لديك ما يكفي في رصيدك");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!success ? (
          <div className=" bg-white border rounded-md p-4 shadow-custom md:width-600 space-y-2 rtl">
            <h1 className="primary-blue font-bold md:text-xl">إنشاء طلب سحب</h1>
            <div className="space-y-3">
              <input
                placeholder="مبلغ"
                className="w-full border rounded-md p-2"
                type="text"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />

              <div className="space-y-2">
                <h1 className="primary-blue font-bold md:text-xl">
                  اختر خيار السحب الخاص بك
                </h1>
                {withdrawOptions?.map((item) => (
                  <div
                    className={`flex justify-between items-center border rounded-md p-2 cursor-pointer ${
                      selectedMethod === item.id && "shadow-md border-gray-500"
                    }`}
                    onClick={() => setSelectedMethod(item.id)}
                  >
                    <div className="flex items-center gap-2">
                      <AiTwotoneBank className="text-reghda-blue border w-16 h-16 rounded-md" />
                      <div>
                        <div className="flex space-x-10">
                          <p className="w-32"> إسم الحساب:</p>
                          <p>{item.account_name}</p>
                        </div>
                        <div className="flex space-x-10 text-gray-500 text-sm">
                          <p className="w-32">نوع الحساب:</p>
                          <p>{item.account_type}</p>
                        </div>
                        <div className="flex space-x-10 text-gray-500 text-sm">
                          <p className="w-32"> رقم حساب:</p>
                          <p>{item.account_number}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="bg-red-700 px-4 py-2 text-white font-semibold rounded-md"
                onClick={handleClose}
              >
                إلغاء
              </button>
              <button
                type="button"
                onClick={handleAddRequest}
                className="bg-reghda-blue px-4 py-2 text-white font-semibold rounded-md"
              >
                صدق
              </button>
            </div>
          </div>
        ) : (
          <div className=" bg-white border rounded-md border-gray-500 p-4 shadow-custom md:width-600 space-y-4">
            <div className="space-y-2">
              <h1 className="text-green-500 text-center text-2xl font-bold">
                النجاح
              </h1>
              <p className="text-center font-semibold primary-blue">
                تم إنشاء طلب السحب الخاص بك بنجاح{" "}
              </p>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className="bg-red-600 px-4 py-2 text-white font-semibold rounded-md"
                onClick={handleClose}
              >
                مغادرة
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
