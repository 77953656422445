import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Tutorials.css";
import CourseCard from "./CourseCard";
import { getTheUserFromStorage } from "../../../services/auth";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../../services/fetcher";
import axios from "axios";
import cn from "classnames";

function CourseTier() {
  const history = useHistory();

  const [active, setActive] = useState("all");
  const [coursesUrl, setCoursesUrl] = useState(
    "https://ragdasairafi369.smartiniaclient.com/courses/all/"
  );

  const freeCourses = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/courses/free/`,
    fetcher
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        "https://ragdasairafi369.smartiniaclient.com/students/mycourses",
        {
          user: getTheUserFromStorage().user_id,
        }
      );

      setData(res.data);

      return res;
    }
    fetch();
  }, []);

  const allStyle = cn("cursor-pointer");
  const freeStyle = cn("cursor-pointer");
  const paidStyle = cn("cursor-pointer");

  return (
    <div className="tutorials">
      <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
        <h1 className="text-pink-300">دوراتي</h1>
      </div>
      <div className="tutorial__container">
        <div className="tutorials__navbar">
          <ul>
            <li
              onClick={() => {
                setActive("all");
              }}
              className={allStyle}>
              {active === "all" ? (
                <p className="text-reghda-blue font-extrabold x-sm:text-base md:text-xl 2xl:text-2xl">
                  كل دوراتي
                </p>
              ) : (
                <p className="text-reghda-black font-extrabold x-sm:text-base md:text-xl 2xl:text-2xl">
                  كل دوراتي
                </p>
              )}
            </li>
            <li
              onClick={() => {
                setActive("free");
              }}
              className={freeStyle}>
              {active === "free" ? (
                <p className="text-reghda-blue font-extrabold x-sm:text-base md:text-xl 2xl:text-2xl">
                  دوراتي المجانية
                </p>
              ) : (
                <p className="text-reghda-black font-extrabold x-sm:text-base md:text-xl 2xl:text-2xl">
                  دوراتي المجانية
                </p>
              )}
            </li>
            <li
              onClick={() => {
                setActive("paid");
              }}
              className={paidStyle}>
              {active === "paid" ? (
                <p className="text-reghda-blue font-extrabold x-sm:text-base md:text-xl 2xl:text-2xl">
                  دوراتي المدفوعة
                </p>
              ) : (
                <p className="text-reghda-black font-extrabold x-sm:text-base md:text-xl 2xl:text-2xl">
                  دوراتي المدفوعة
                </p>
              )}
            </li>
          </ul>
        </div>
        {active === "free" && (
          <>
            <div className="courses__container">
              {freeCourses &&
                freeCourses.data.length !== 0 &&
                freeCourses.data.map((course, i) => (
                  <CourseCard
                    key={course.id}
                    courseDetail={course}></CourseCard>
                ))}
            </div>
            {freeCourses && freeCourses.data.length === 0 && (
              <div className="h-48 flex justify-center items-center bg-white rounded-xl ">
                <p className="text-3xl text-reghda-black">
                  ليس لديك دورات مجانية
                </p>
              </div>
            )}
          </>
        )}
        {active === "paid" && (
          <>
            <div className="courses__container">
              {data &&
                data.enrolled_courses.map((course, i) => (
                  <CourseCard
                    key={course.id}
                    courseDetail={course}></CourseCard>
                ))}
            </div>
            {data && data.enrolled_courses.length === 0 && (
              <div className="h-48 flex justify-center items-center bg-white rounded-xl ">
                <p className="text-3xl text-reghda-black">
                  ليس لديك دورات مدفوعة
                </p>
              </div>
            )}
          </>
        )}
        {active === "all" && (
          <>
            <div className="courses__container">
              {data &&
                freeCourses &&
                freeCourses.data &&
                data.enrolled_courses &&
                [...freeCourses.data, ...data.enrolled_courses].map(
                  (course, i) => (
                    <CourseCard
                      key={course.id}
                      courseDetail={course}></CourseCard>
                  )
                )}
            </div>
            {data &&
              freeCourses &&
              freeCourses.data &&
              [...freeCourses.data, ...data.enrolled_courses].length === 0 && (
                <div className="h-48 flex justify-center items-center bg-white rounded-xl ">
                  <p className="text-3xl text-reghda-black">ليس لديك دورات</p>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
}

export default CourseTier;
