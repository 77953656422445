import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const BookCard = ({ book }) => {
  const history = useHistory();

  return (
    <div
      onClick={() => history.push(`/books/${book.type}/${book.slug}`, { book })}
      className="w-full h-full cursor-pointer x-sm:p-1 sm:p-4 space-y-2"
    >
      <div className="w-full h-full flex flex-col items-center space-y-4 text-gray-600 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl font-bold">
        {book.image && (
          <img
            src={book.image}
            alt="course-pic"
            className="shadow-2xl w-full h-full object-cover"
          />
        )}
        <h6 className="text-center x-sm:text-sm sm:text-lg lg:text-xl 2xl:text-2xl">
          {book.name}
        </h6>
        {/*    {book.price && (
          <p className="text-center x-sm:text-xs sm:text-sm lg:text-lg 2xl:text-xl font-normal">{`السعر : ${book.price} ريال`}</p>
        )} */}
      </div>
    </div>
  );
};

export default BookCard;
