import React from "react";
import Modal from "@material-ui/core/Modal";

export default function SimpleModal({ open, handleClose, image }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className=" bg-white border rounded-md border-gray-500 p-4 shadow-custom md:width-600 space-y-2">
          <img src={image} />
        </div>
      </Modal>
    </div>
  );
}
