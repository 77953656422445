import React, { useState } from "react";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const BookViewer = ({ url }) => {
  const screenWidth = useWindowWidth();

  return screenWidth >= 768 ? (
    <div className="h-screen max-h-screen flex flex-col items-center justify-center x-sm:pb-10 md:pb-16 2xl:pb-20">
      <div className="h-full x-sm:w-full md:w-2/3">
        <iframe
          className="embed-responsive-item"
          title="file"
          style={{ width: "100%", height: "100%" }}
          src={
            (url.includes("ragdasairafi369.smartiniaclient.com")
              ? url.replace("http", "https")
              : url) + `#zoom=${screenWidth >= 768 ? "75" : "25"}`
          }
        />
      </div>
      <a
        href={
          url.includes("ragdasairafi369.smartiniaclient.com")
            ? url.replace("http", "https")
            : url
        }
        target="_blank"
        rel="noreferrer"
        className="bg-gradient-to-r from-pink-400 to-indigo-300 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-xl mt-12 outline-none focus:outline-none">
        افتح الكتاب في صفحة جديدة
      </a>
    </div>
  ) : (
    <div className="flex flex-row items-center justify-center my-8">
      <a
        href={
          url.includes("ragdasairafi369.smartiniaclient.com")
            ? url.replace("http", "https")
            : url
        }
        target="_blank"
        rel="noreferrer"
        className="bg-gradient-to-r from-pink-400 to-indigo-300 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
        افتح الكتاب
      </a>
    </div>
  );
};

export default BookViewer;
