import React, { useState, useEffect } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import { IconContext } from "react-icons";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";

const PeopleSaid = () => {
  const [sayings, setSayings] = useState([]);
  const [current, setCurrent] = useState(0);

  const { data, error } = useSWR(
    `https://ragdasairafi369.smartiniaclient.com/about/sayings/`,
    fetcher
  );

  useEffect(() => {
    let newArr = [];
    data &&
      data.map((say) => {
        newArr.push(say);
        return say;
      });

    data && data.length && setSayings(newArr);
  }, [data]);

  const next = () => {
    if (current < sayings.length - 1) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };

  const previous = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(sayings.length - 1);
    }
  };

  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="h-full text-center sm:p-8 md:p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600 font-extrabold x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          <img
            className="x-sm:w-full md:w-1/2"
            src="/images/people-said-title.png"
            alt="pst"
          />
          <IconContext.Provider
            value={{
              size: "3em",
              style: { color: "#e7bdcb" },
            }}>
            <div className="flex flex-row items-center justify-around h-full w-full">
              <div onClick={previous} className="cursor-pointer">
                <AiFillLeftCircle></AiFillLeftCircle>
              </div>
              {data && sayings.length && (
                <div className="psm x-sm:w-full x-sm:h-64 md:w-2/3 md:min-h-screen flex flex-col items-center justify-center x-sm:text-xxs x-sm:px-8 sm:text-xs sm:px-12 md:text-sm md:px-20 lg:text-lg lg:px-28 2xl:text-4xl 2xl:px-48">
                  <p className="leading-loose">{sayings[current].body}</p>
                  <div className="self-start font-extrabold ml-2">
                    <p>ـ {sayings[current].author} ـ</p>
                  </div>
                </div>
              )}
              <div onClick={next} className="cursor-pointer">
                <AiFillRightCircle></AiFillRightCircle>
              </div>
            </div>
          </IconContext.Provider>
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default PeopleSaid;
