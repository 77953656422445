import React, { useState } from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import { Link } from "react-router-dom";

const Questions = () => {
  return (
    <div className="my-0 mx-auto h-auto">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <div className="h-full text-center p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600">
          <h1 className="font-extrabold x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
            أسئلة متكررة
          </h1>
          <div className="text-right x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl leading-loose space-y-6">
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              كيف أستطيع شراء البرامج والدورات والمنتجات؟
            </h1>
            <p className="font-medium leading-loose break-words">
              تستطيع الشراء بسهولة عبر موقعنا:باستخدام طرق الدفع التالية: فيزا/
              ماستر كارد/ باي بال. وفي حال الرغبة في مساعدة أكثر يسعدنا تواصلك
              معنا عبر الواتس اب 00966540020724 لتزويدك بطرق اخرى للدفع كالتحويل
              البنكي او الويسترن يونيون
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل الدورات حضورية أو أون لاين ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              جميع دوراتنا أون لاين )مسجلة( وليست مباشرة ويمكنك حضورها من بيتك
              في أي وقت
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              أين أ جد البرامج والكتب والدورات التي اقتنيتها ؟ لم تصلني عبر
              الايميل! ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              ستجدها في حسابك في مركز رغده طلال صيرفي للتدريب فور اقتنائك لها
              عبر دخولك لحسابك ثم الذهاب لأيقونة حسابي ثم خانة دوراتي للبرامج
              والدورات وخانة كتبي للكتب
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل البرامج والدورات والكتب التي أشتريها لها تاريخ انتهاء ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              لا، ليس لها تاريخ انتهاء، بإمكانك الاستمتاع بها من بيتك في أي وقت
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل بإمكاني تحميل المواد والكتب لجهازي الخاص؟
            </h1>
            <p className="font-medium leading-loose break-words">
              نعم تتوفر خاصية التحميل بشكل مسموع لجميع موادنا
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل من الممكن التواصل معك أستاذة رغده للاستفسار عن البرامج والدورات
              والكتب التي اشتريتها ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              في جميع البرامج والمواد والمنتجات التي أقدمها أحرص على تقديم مادة
              سهلة وواضحة وشاملة، وستجد في مضمون البرنامج أو الدورة الكثير من
              الأسئلة التوضيحية عن الماد ة وأجوبتها، يسعدني التواصل مع الجميع
              لكن في الوقت الحالي يتعذر استقبال الاستفسارات بشكل شخصي بسبب ضخامة
              عدد المستفيدين . بإمكانك ذلك في حال إشتراكك ببرنامج كوني ملكة الحب
              أو الحجز للجلسات العلاجية وجلسات الكوتشنج
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              أنا مبتدئة في مجال التنمية الذاتية، ماهي البرامج والدورات التي
              تنصحيني بها ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              كمبتدئة أخبرك بأنه من الأفضل أن تبدأي بتسلسل الدورات كما هو موضح
              في الموقع مثلا: دورة لذة الروح ستكون المستوى الأول دورة أسرار
              صناعة التغيير الشخصي ستكون المستوى الثاني دورة أسرار الحب الأبدي
              ستكون المستوى الثالث في حال كنت متزوجة أو مخطوبة أو مقبلة على
              الزواج دورة أسرار النية ستكون المستوى الرابع دورة برمجة عقلية
              ستكون المستوى الخامس دورة كوني أنتِ ستكون المستوى السادس وهكذا
              سيكون مكتوبا عند كل دورة سيتم الاعلان عنها المستوى الخاص بها
              لتحقيق أكبر إستفادة من الدورات والبرامج * هل من الممكن أن احضر
              أكثر من دورة وبرنامج في وقت واحد ؟ يمكن ذلك، لكن لا أحبذ هذه
              الخطوة إلا في حال كونك معتادا على حضور الدورات والبرامج المكثفة،
              فيما عدا ذلك يفضل التركيز على برنامج أو دورة واتمامها ثم الانتقال
              للمادة التالية
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              ماهي البرامج السنوية ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              البرنامج السنوي هو برنامج كوني ملكة الحب للمتزوجات فقط يقدم مرة
              واحدة كل عام وهو تطبيقي أكثر ويحتاج للتفرغ والعمل والإجتهاد
              وبالإمكان الدخول به فقط وسيغنيك عن بعض الدورات الأخرى حيث أنه مدته
              تمتد لخمسة أشهر متواصلة ويشمل تغيير جميع جوانب الحياة لكل أنثى
              متزوجة لتصل للتغيير السليم والتوازن الشامل ويهدف لعمل تغيير متدرج
              وجذري.ويتميز بتواجد الأستاذة رغده معك طيلة هذه الرحلة
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل هذه البرامج والدورات ستساعدني في حل مشاكلي؟
            </h1>
            <p className="font-medium leading-loose break-words">
              نعم هذه البرامج والدورات ستساعدك في حل مشاكلك في حال اختيار المادة
              المناسبة واستيعاب المعلومات وتطبيقها وعمل التمارين الموجودة في
              البرنامج أو الدورة
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل بإمكان شخص آخر حضور البرنامج أو الدورة معي؟
            </h1>
            <p className="font-medium leading-loose break-words">
              لا يُسمَح بمشاركة معلومات الحساب أو إرسال المواد لشخص آخر
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل بإمكاني نشر المعلومات للفائدة؟
            </h1>
            <p className="font-medium leading-loose break-words">
              لا نفضل أن تنشري للأخرين معلومات وقد يكونو غير مستعدين لهذه
              المعلومات لذلك نفضل أن تقومي بتطبيقها في حياتك وعندما يشاهدون
              النتيجة سيحفز ذلك الرغبة لديهم للتعلم والتغيير حيث أن التغيير
              تحتاج الى تسلسل وتدرج وتكامل ويفضل أن تدليهم على منتجاتنا وخدماتنا
              للإستفادة القصوى
            </p>
            <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              هل تحتوي البرامج والدورات والكتب على تمارين وتطبيقات ؟
            </h1>
            <p className="font-medium leading-loose break-words">
              جميع موادنا تحتوي على تمارين وتطبيقات لأنها ضرورية للحصول على
              النتائج المرغوبة. والتمارين والتطبيقات تؤثر على تحسين حياتك
              وتغييرها ٧٠٪ من النتائج لذلك مهم جدا عمل التطبيقات والتمارين
              للاستفادة الحقيقية الكاملة من البرامج
            </p>
            <h1 className="text-center font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
              سياسة الإرجاع
            </h1>
            <p className="text-center font-medium leading-loose break-words">
              يرجى الاطلاع على سياسة الإرجاع الخاصة بموقع رغده صيرفي من هنا
              <span className="text-reghda-blue">
                <Link to="/return"> سياسة الإرجاع</Link>
              </span>{" "}
            </p>
          </div>
        </div>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Questions;
