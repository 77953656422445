import React from "react";
import Navbar from "./../Navbar";
import Contact from "./../Contact";
import Footer from "./../Footer";
import Detail from "./content/Detail";

const BookDetailPage = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <Detail></Detail>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default BookDetailPage;
